// has dependencies on jQuery and moment.js
var PeopleMedia = (function ($jq, moment) {
    "use strict";

    var Oid = "PeopleMedia";

    var _adReloadTimeout = null;
    var _resizeTimeout = null;
    var _listeningOnResize = false;
    var _adjustMainMarginTimeout = null;
    var _deviceTypeCookieName = "DEVICEVIEWTYPE";
    var _mobileDeviceType = "mobile320x480";
    var _desktopDeviceType = "fullsite960w";

    var _adjustMainMargin = function () {
        try {
            var $main = $jq("div.main");
            var mainHeight = $main.outerHeight(true);
            var rightColumnHeight = $jq("div.right-column").outerHeight(true);
            if (rightColumnHeight > 0 && mainHeight > 0) {
                var diff = rightColumnHeight - mainHeight;
                if (diff > 0) {
                    // adjust div.main bottom margin to prevent right-column from overlapping the area below div.main
                    var newMargin = diff + 10;
                    var currentMargin = +($main.css("margin-bottom").replace("px", ""));
                    if (newMargin > currentMargin) {
                        $main.css("margin-bottom", newMargin);
                    }
                }
            }
        } catch (e) {
        }
    };

    var adjustMainMargin = function () {
        clearTimeout(_adjustMainMarginTimeout);
        _adjustMainMarginTimeout = setTimeout(_adjustMainMargin, 500);
    };

    var adSlotRenderEnded = function (event) {
        try {
            if (event && !event.isEmpty) {
                // var tile = event.slot.getTargeting("tile");
                adjustMainMargin();
            }
        } catch (e) {
        }
    };

    var amazonAdCallback = function () {
        if (typeof (amznads) !== "undefined") {
            try {
                amznads.setTargetingForGPTAsync(PeopleMedia.AmazonAdKey);
            } catch (e) {
                /* ignore */
            }
            googletag.pubads().refresh();
        }
    };

    var _reloadAds = function () {
        _adReloadTimeout = null;
        try {
            if (typeof (googletag) !== "undefined") {
                googletag.pubads().updateCorrelator();
                googletag.pubads().setTargeting("ord", getRandomNumericString(10));
                if (typeof (amznads) !== "undefined" && PeopleMedia.AmazonAdSourceId && PeopleMedia.AmazonAdKey) {
                    try {
                        amznads.getAdsCallback(PeopleMedia.AmazonAdSourceId, PeopleMedia.AmazonAdCallback);
                        googletag.pubads().clearTargeting(PeopleMedia.AmazonAdKey);
                    } catch (e) {
                        /* ignore */
                    }
                } else {
                    // Clear targeting for DFP slots
                    var slots = googletag.pubads().getSlots();
                    if (typeof (slots) !== "undefined" && slots !== null) {
                        slots.forEach(function (slot) {
                            slot.clearTargeting('amznsz');
                            slot.clearTargeting('hb_size_appnexus');
                            slot.clearTargeting('hb_pb_appnexus');
                            slot.clearTargeting('hb_adid_appnexus');
                            slot.clearTargeting('hb_bidder_appnexus');
                            slot.clearTargeting('hb_size');
                            slot.clearTargeting('hb_pb');
                            slot.clearTargeting('hb_adid');
                            slot.clearTargeting('hb_bidder');
                            slot.clearTargeting('hb_size_aol');
                            slot.clearTargeting('hb_pb_aol');
                            slot.clearTargeting('hb_adid_aol');
                            slot.clearTargeting('hb_bidder_aol');
                            slot.clearTargeting('hb_size_openx');
                            slot.clearTargeting('hb_pb_openx');
                            slot.clearTargeting('hb_adid_openx');
                            slot.clearTargeting('hb_bidder_openx');
                            slot.clearTargeting('hb_size_sovrn');
                            slot.clearTargeting('hb_pb_sovrn');
                            slot.clearTargeting('hb_adid_sovrn');
                            slot.clearTargeting('hb_bidder_sovrn');
                            slot.clearTargeting('hb_size_indexExchang');
                            slot.clearTargeting('hb_pb_indexExchange');
                            slot.clearTargeting('hb_adid_indexExchang');
                            slot.clearTargeting('hb_bidder_indexExcha');
                        });
                    }
                    googletag.pubads().refresh();
                }
            }
            setTimeout(PeopleMedia.RefreshSession, 600);
        } catch (e) {
        }
    };

    var reloadAds = function () {
        if (_adReloadTimeout == null) {
            _adReloadTimeout = setTimeout(_reloadAds, 400);
        }
    };

    var _resizeComplete = function () {
        _reloadAds();
    };

    var resizeHandler = function () {
        clearTimeout(_resizeTimeout);
        _resizeTimeout = setTimeout(_resizeComplete, 500);
    };

    var initializeResizeHandler = function () {
        $jq(window).on("resize", resizeHandler);
        _listeningOnResize = true;
    };

    var initializeOrientationHandler = function () {
        $jq(window).on("orientationchange", resizeHandler);
        if (_listeningOnResize) {
            $jq(window).off("orientationchange", resizeHandler);
            _listeningOnResize = false;
        }
    };

    var getRandomNumericString = function (numberOfDigits) {
        var result = "";
        try {
            for (var i = 0; i < +numberOfDigits; i++) {
                result += getRandomInt(0, 9);
            }
        } catch (e) {
        }
        return result;
    };

    var getRandomInt = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    var refreshSession = function () {
        if (!PeopleMedia.PersistentLoginEnabled && PeopleMedia.IsLoggedIn) {
            var startTime = new Date().getTime();
            var url = "/v3/ajax/RefreshSession";
            var timeoutValue = 60000;
            $jq.ajax({
                type: "GET",
                url: url,
                data: {},
                dataType: "json",
                success: function (response) {
                    if (response.redirect) {
                        location.href = response.redirect;
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    if (errorThrown === "timeout" || (jqXHR.readyState !== 0 && jqXHR.status !== 0)) {
                        var endTime = new Date().getTime();
                        var duration = (endTime - startTime);
                        // PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                        PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid:Oid, method: "refreshSession", duration: duration }, jqXHR, textStatus, errorThrown, function () {
                        });
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
            PeopleMedia.Timer.StartCountdownTimer();
        }
    };

    var resolveSessionToken = function () {
        if (typeof (PMChat) !== "undefined" &&
            PMChat !== null &&
            typeof (PMChat.Config) !== "undefined" &&
            PMChat.Config != null &&
            typeof (PMChat.Config.DefaultSessionToken) !== "undefined" &&
            PMChat.Config.DefaultSessionToken) {
            return PMChat.Config.DefaultSessionToken;
        }
        return null;
    };

    var initializeChat = function () {
        if (typeof (PMChat) !== "undefined" && PMChat !== null) {
            try {
                PMChat.On("ready", function () {
                    PMChat.On("error", function (e) {
                        if (!e.Error.Handled) {
                            var pmError = {
                                message: e.Error.getMessage(),
                                name: e.Error.getTitle()
                            };
                            if (typeof (PMChat.ChatLoggingEnabled) !== "undefined" && PMChat.ChatLoggingEnabled) {
                                PeopleMedia.Logger.LogExceptionWithDetail(pmError, { method: "PMChat.OnError" });
                            }
                        }
                    });

                    PMChat.UI.Init({
                        SiteHostName: PeopleMediaConfig.Configuration.siteHostName,
                        SiteImagePath: PeopleMediaConfig.Configuration.siteImagePath,
                        ThemeImagePath: PeopleMediaConfig.Configuration.themeImagePath,
                        CommonImagePath: PeopleMediaConfig.Configuration.commonImagePath
                    });
                });

                PMChat.On("stateloaded", function (e) {
                    PMChat.GetNewChats();
                });

                PMChat.UI.On("togglefavorite", function (e, data) {
                    var isFavorite = data.IsFavorite;
                    var favoriteAction = (isFavorite === true) ? PeopleMedia.Favorite.removeFavorite : PeopleMedia.Favorite.addFavorite;

                    favoriteAction({
                        encodedMemberId: data.MemberId,
                        suppressErrors: true,
                        onComplete: function (err, response, data) {
                            if (err && response === null) {
                                PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                                return;
                            }
                            if (response && response.status === "Success") {
                                var favoriteText = $jq(".chatTab[data-with=\"" + data.MemberId + "\"] > .chatTabSettingsMenu > div > div:first-child");
                                if (typeof (favoriteText) === "undefined" || favoriteText.length === 0)
                                    favoriteText = $jq(".chatTabSettingsMenu >  div > div:first-child");

                                var miniProfileFavDiv = $jq("div.chatTab[data-with=" + data.MemberId + "] div.chatTabUser .favorite");
                                if (typeof (miniProfileFavDiv) === "undefined" || miniProfileFavDiv.length === 0) {
                                    miniProfileFavDiv = $jq("div.chatTab[data-with=" + data.MemberId + "] div.chatTabUser .favorited");
                                }

                                if (isFavorite === true) {
                                    favoriteText.html("Done!");
                                    miniProfileFavDiv.addClass("favorite").removeClass("favorited").text("Save");
                                }
                                else {
                                    favoriteText.html("Done!");
                                    miniProfileFavDiv.addClass("favorited").removeClass("favorite").text("Saved");
                                }

                                PMChat._Clients[data.MemberId].WithMember.IsFavorite = !isFavorite;

                                setTimeout(function () {
                                    if (isFavorite === true) {
                                        favoriteText.html("Save");
                                    }
                                    else {
                                        favoriteText.html("Saved");
                                    }
                                }, 2000);
                            }
                        }
                    });
                    
                });

                PMChat.UI.On("blockmember", function (e, data) {
                    PeopleMedia.Popups.BlockUser({
                        encodedMemberId: data.MemberId,
                        username: data.NickName
                    });
                });

                PMChat.UI.On("reportaconcern", function (e, data) {
                    PeopleMedia.Popups.ReportConcern({
                        encodedMemberId: data.MemberId,
                        username: data.NickName
                    });
                });

                PMChat.Init({
                    SiteID: PeopleMediaConfig.Configuration.currentUserSiteId,
                    MemberID: PeopleMediaConfig.Configuration.profile,
                    NickName: PeopleMediaConfig.Configuration.username,
                    ChatLoggingEnabled: PeopleMediaConfig.Configuration.chatLoggingEnabled,
                    ChatEnabled: (PMChat.ChatEnabled === true),
                    DefaultSessionToken: PeopleMediaConfig.Configuration.defaultSessionToken,
                    SessionToken: PeopleMedia.resolveSessionToken,
                    State: new PMChat.WebStorageState(),
                    Channel: new PMChat.WebSyncChannel({
                        Client: function () {
                            PeopleMedia.WebSyncServer.Init();
                            return PeopleMedia.WebSyncServer.Client;
                        },
                        Channel: PeopleMediaConfig.Configuration.channel
                    }),
                    BaseUrl: PeopleMediaConfig.Configuration.chatBasePath,
                    API: {
                        BaseUrl: PeopleMediaConfig.Configuration.chatApi
                    }
                });

                PMChat.UI.SetToggleText();
            }
            catch (ex) {
                if (typeof (PMChat) !== "undefined" && PMChat !== null && typeof (PMChat.ChatLoggingEnabled) !== "undefined" && PMChat.ChatLoggingEnabled) {
                    PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid:Oid, method: "PMChat.On" });
                }
            }
        }
    };

    var handleError = function (response) {
        if (response) {
            if (response.redirect) {
                location.href = response.redirect;
            } else if (response.message) {
                PeopleMedia.Popups.ShowGenericMessage(response.message);
            }
        }
    };

    // needed to offset the scrolling when there is a fixed header
    var scrollToItem = function($elem) {
        try {
            var headerPosition;
            var headerAdjustment;
            var elemPosition;
            var scrollTop;
            if ($elem && $elem.length > 0) {
                elemPosition = $elem.offset();
                if (elemPosition) {
                    headerPosition = $jq(".layout-container-fixed .layout-container-header").css("position");
                    headerAdjustment = (headerPosition === "fixed") ? ($jq("div.header").height() || 0) : 0;
                    scrollTop = elemPosition.top - headerAdjustment;
                    $jq("html, body").animate({
                        scrollTop: scrollTop,
                        scrollLeft: 0
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    var useScrollingForRelativeLinks = function ($container) {
        try {
            if ($container && $container.length > 0) {
                $container.find("a[href^='#']").on("click", function (event) {
                    var $this = $jq(this);
                    var href = $this.attr("href");
                    var $link = $container.find(href);
                    if ($link && $link.length > 0) {
                        event.preventDefault();
                        event.stopPropagation();
                        PeopleMedia.scrollToItem($link);
                    }
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    var sitePostbackHeader = function () {
        var header = {};
        try {
            var tokenName = "__RequestVerificationToken";
            var $af = $jq("#sitervt");
            if ($af.length > 0) {
                var $token = $af.find("input[name='" + tokenName + "']");
                if ($token.length > 0) {
                    header[tokenName] = $token.val();
                }
            }
        } catch (ex) {
        }
        return header;
    };

    var sitePostback = function ($form) {
        try {
            var tokenName = "__RequestVerificationToken";
            if ($form && $form.length > 0) {
                var $token = $form.find("input[type='hidden'][name='" + tokenName + "']");
                if ($token.length === 0) {
                    var header = PeopleMedia.sitePostbackHeader();
                    if (header && header[tokenName]) {
                        $form.prepend($jq("<input name=\"" + tokenName + "\" type=\"hidden\" value=\"" + header[tokenName] + "\">"));
                    }
                }
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "sitePostback" });
        }
    };

    var createErrorObject = function (prop) {
        var errObj;
        try {
            var errProp = prop || {};
            errObj = $jq.extend({}, { reason: "error" }, errProp);
        } catch (ex) {
            errObj = { reason: "error" };
        }
        return errObj;
    };

    var createAjaxErrorObject = function (prop) {
        var errObj;
        try {
            var errProp = prop || {};
            var generalError = createErrorObject(errProp);
            var errorThrown = (typeof (errProp.errorThrown) !== "undefined") ? errProp.errorThrown : null;
            var isCancelled = false;
            var reason = (typeof (errProp.reason) !== "undefined" && errProp.reason) ? errProp.reason : null;
            var responseText = null;
            var statusCode = null;
            if (typeof (errProp.jqXHR) !== "undefined" && errProp.jqXHR !== null) {
                isCancelled = (errorThrown !== 'timeout' && (errProp.jqXHR.readyState === 0 || errProp.jqXHR.status === 0));
                reason = reason || errorThrown;
                responseText = (!isCancelled && errProp.jqXHR.responseText) ? errProp.jqXHR.responseText : null;
                statusCode = errProp.jqXHR.status;
            }
            errObj = $jq.extend({}, generalError, {
                reason: reason || "error",
                isAjaxError: true,
                isCancelled: isCancelled,
                responseText: responseText,
                statusCode: statusCode
            });
        } catch (ex) {
            errObj = { reason: "error", isAjaxError: true };
        }
        return errObj;
    };

    var convertToPositiveInteger = function(input) {
        var result = 0;
        if (typeof (input) === "number") {
            result = Math.trunc(input);
        } else if (typeof (input) === "string") {
            result = parseInt(input, 10);
            if (isNaN(result)) {
                result = 0;
            }
        }
        return (result >= 0) ? result : 0;
    };

    // HTML escape
    var htmlEscape = function (str) {
        return String(str)
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    };

    var transitionEndEventName = function () {
        var i;
        var el = document.createElement('div');
        var transitions = {
            'transition': 'transitionend',
            'OTransition': 'otransitionend',  // oTransitionEnd in very old Opera
            'MozTransition': 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd'
        };

        for (i in transitions) {
            if (transitions.hasOwnProperty(i) && typeof (el.style[i]) !== "undefined") {
                return transitions[i];
            }
        }
        return null;
    };

    var getCovidHomeSessionStorageKey = function () {
        var CurrentPublicMemberId = null;
        if (typeof (PeopleMediaConfig) !== "undefined" &&
            PeopleMediaConfig !== null &&
            typeof (PeopleMediaConfig.Configuration) !== "undefined" &&
            PeopleMediaConfig.Configuration !== null) {
            CurrentPublicMemberId = PeopleMediaConfig.Configuration.publicMemberId;
        }
        return "CovidHome_" + (CurrentPublicMemberId || "");
    };

    var getMatchstackEventsAdNoThanksClickedStorageKey = function () {
        var CurrentPublicMemberId = null;
        if (typeof (PeopleMediaConfig) !== "undefined" &&
            PeopleMediaConfig !== null &&
            typeof (PeopleMediaConfig.Configuration) !== "undefined" &&
            PeopleMediaConfig.Configuration !== null) {
            CurrentPublicMemberId = PeopleMediaConfig.Configuration.publicMemberId;
        }
        return "Matchstack_EventsAdNoThanksClicked_" + (CurrentPublicMemberId || "");
    };

    var getMatchStackSessionStorageKey = function() {
        // include current member id as part of key in case someone logs in with a different user in the same session
        var CurrentPublicMemberId = null;
        if (typeof (PeopleMediaConfig) !== "undefined" &&
            PeopleMediaConfig !== null &&
            typeof (PeopleMediaConfig.Configuration) !== "undefined" &&
            PeopleMediaConfig.Configuration !== null) {
            CurrentPublicMemberId = PeopleMediaConfig.Configuration.publicMemberId;
        }
        return "MatchStack_" + (CurrentPublicMemberId || "");
    };

    var getSettingsNotificationStorageKey = function () {
        // include current member id as part of key in case someone logs in with a different user in the same session
        var CurrentPublicMemberId = null;
        if (typeof (PeopleMediaConfig) !== "undefined" &&
            PeopleMediaConfig !== null &&
            typeof (PeopleMediaConfig.Configuration) !== "undefined" &&
            PeopleMediaConfig.Configuration !== null) {
            CurrentPublicMemberId = PeopleMediaConfig.Configuration.publicMemberId;
        }
        return "NewSettingsNotification_ClearNotification_" + (CurrentPublicMemberId || "");
    };

    var findLatestExpiresAt = function (arr) {
        var latest = null;
        try {
            var latestMoment = moment("0000-01-01");
            if (Array.isArray(arr) && arr.length > 0) {
                for (var i = arr.length - 1; i >= 0; i--) {
                    if (arr[i] && arr[i].hasOwnProperty("expiresAt") && arr[i].expiresAt) {
                        var expiresAt = moment(arr[i].expiresAt);
                        if (!latestMoment.isAfter(expiresAt)) {
                            latestMoment = expiresAt;
                            latest = arr[i];
                        }
                    }
                }
                // use last item if no expiresAt values
                if (latest === null) {
                    latest = arr[arr.length - 1];
                }
            }
        } catch (e) {
        }
        return latest;
    };

    // returns array of querystring results as objects with "key" and "value" properties
    // parseQueryStringToArray("a=123&b=456&a=789") 
    //   returns [0] = { key: "a", value: "123" }
    //           [1] = { key: "b", value: "456" }
    //           [2] = { key: "a", value: "789" }
    var parseQueryStringToArray = function (queryString) {
        var result = [];
        var qs = (typeof (queryString) === "undefined" || queryString === null) ? window.location.search : queryString;
        var searchParams = new URLSearchParams(qs);     // jshint ignore:line
        searchParams.forEach(function (value, key) {
            if (key) {
                result.push({ key: key, value: value });
            }
        });
        return result;
    };

    // finds matching querystring items returned from parseQueryStringToArray
    // key comparison is case-insensitive
    // allows converting the value to a specific type (boolean, number, int, string)
    // getQueryStringItem([{ key: "A", value: "123" }], "a", "int")
    //   returns [0] = 123
    var getQueryStringItem = function (qsArray, key, type) {
        if (qsArray && Array.isArray(qsArray) && key) {
            var keyLower = key.toLowerCase();
            var num;
            return qsArray.filter(function (item) {
                return item && item.key && item.key.toLowerCase() === keyLower;
            }).map(function (item) {
                if (typeof (item) === "undefined" || item === null || typeof (item.value) === "undefined" || item.value === null) {
                    return null;
                }
                switch (type) {
                    case "boolean":
                        return !!(item.value);
                    case "number":
                        num = parseFloat(item.value);
                        return (!isNaN(num)) ? num : null;
                    case "int":
                        num = parseInt(item.value, 10);
                        return (!isNaN(num)) ? num : null;
                    case "string":
                        return item.value.toString();
                    default:
                        return item.value;
                }
            });
        }
        return [];
    };

    var arrayFirstOrDefault = function(arr) {
        return (arr.length > 0) ? arr[0] : null;
    };

    var isRushHour = function (date) {
        var dateCheck = (date && Object.prototype.toString.call(date) === '[object Date]') ? date : new Date();
        var hour = dateCheck.getHours();
        if (isNaN(hour)) {
            return false;
        }
        // boost rush hour is 9AM-12PM and 3PM-10PM (PMWEB-6618)
        return ((hour >= 9 && hour < 12) || (hour >= 15 && hour < 22));
    };

    var rushHourEndsAt = function (date) {
        var dateCheck = (date && Object.prototype.toString.call(date) === '[object Date]') ? date : new Date();
        var hour = dateCheck.getHours();
        if (isNaN(hour) || !isRushHour(dateCheck)) {
            return null;
        }
        var hourEnd = 0;
        if (hour >= 9 && hour < 12) {
            hourEnd = 12;
        } else if (hour >= 15 && hour < 22) {
            hourEnd = 22;
        }
        dateCheck.setHours(hourEnd);
        dateCheck.setMinutes(0);
        dateCheck.setSeconds(0);
        dateCheck.setMilliseconds(0);
        return dateCheck;
    };

    var isCommandKey = function (key) {
        // movement or special keys
        // backspace, caps lock, shift, ctrl, alt, end, home, left, right, up, down, pgup, pgdn, ins, del
        return (key === 8 || key === 20 || (key >= 16 && key <= 18) || (key >= 33 && key <= 40) || key === 45 || key === 46);
    };

    var getDeviceViewTypeCookie = function () {
        return PeopleMedia.Cookies.getCookie(_deviceTypeCookieName);
    };

    var setDeviceViewTypeCookie = function (value) {
        if (value === _desktopDeviceType || value === _mobileDeviceType) {
            PeopleMedia.Cookies.setCookie(_deviceTypeCookieName, value, 30, true);
        } else {
            PeopleMedia.Cookies.removeCookie(_deviceTypeCookieName);
        }
    };

    var detectIOS = function () {
        // IE11 or Android
        if (window.MSStream || navigator.userAgent.match(/Android/i)) {
            return false;
        }
        // include Macintosh for iPad after iOS 13
        return (navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/Macintosh/i)
        );
    };

    var detectAndroid = function () {
        return navigator.userAgent.match(/Android/i);
    };

    var switchToMobileView = function() {
        var current = getDeviceViewTypeCookie();
        if (current !== _mobileDeviceType) {
            setDeviceViewTypeCookie(_mobileDeviceType);
            PeopleMedia.Tracking.trackSwitchDeviceType({
                eventName: "SwitchToMobileView",
                previousValue: current,
                onComplete: function () {
                    window.location.reload();
                }
            });
        }
    };

    // removes keys from object while keeping same reference (unlike obj = {} which creates a new reference)
    // obj = object to update
    // except = array of any property names that should not be removed (optional)
    var clearObjectProperties = function (obj, except) {
        var keys = Object.keys(obj);
        if (Array.isArray(except) && except.length > 0) {
            keys = keys.filter(function (key) {
                return key && except.indexOf(key) === -1;
            });
        }
        for (var i = 0; i < keys.length; i++) {
            delete obj[keys[i]];
        }
    };

    return {
        SiteId: PeopleMediaConfig.Configuration.siteId,
        Domain: PeopleMediaConfig.Configuration.domain,
        BaseDomain: PeopleMediaConfig.Configuration.baseDomain,
        SiteImagePath: PeopleMediaConfig.Configuration.siteImagePath,
        SiteRootEnabled: true,
        PersistentLoginEnabled: PeopleMediaConfig.Configuration.persistentLoginEnabled,
        IsLoggedIn: (PeopleMediaConfig.Configuration.isLoggedIn),
        PopupPath: PeopleMediaConfig.Configuration.popupPath,
        WebSyncUrl: PeopleMediaConfig.Configuration.webSyncUrl,
        ImagePath: PeopleMediaConfig.Configuration.imagePath,
        ThemeImagePath: PeopleMediaConfig.Configuration.themeImagePath,
        CommonImagePath: PeopleMediaConfig.Configuration.commonImagePath,
        BaseImagePath: PeopleMediaConfig.Configuration.baseImagePath || "https://pmi.peoplemedia.com/pmicontent",
        PageDefinitionId: PeopleMediaConfig.Configuration.pageDefinitionId,
        EncodedMemberId: PeopleMediaConfig.Configuration.encodedMemberId,
        CombineFlirtsAndLikes: PeopleMediaConfig.Configuration.combineFlirtsAndLikes,
        DisableLegacyChat: PeopleMediaConfig.Configuration.disableLegacyChat,
        ShowMatchMutualConnections: PeopleMediaConfig.Configuration.showMatchMutualConnections,
        ApiSendEnabled: PeopleMediaConfig.Configuration.apiSendEnabled,
        AdjustMainMargin: adjustMainMargin,
        AdSlotRenderEnded: adSlotRenderEnded,
        AmazonAdCallback: amazonAdCallback,
        ReloadAds: reloadAds,
        ResizeHandler: resizeHandler,
        InitializeResizeHandler: initializeResizeHandler,
        InitializeOrientationHandler: initializeOrientationHandler,
        RefreshSession: refreshSession,
        resolveSessionToken: resolveSessionToken,
        InitializeChat: initializeChat,
        handleError: handleError,
        scrollToItem: scrollToItem,
        useScrollingForRelativeLinks: useScrollingForRelativeLinks,
        sitePostbackHeader: sitePostbackHeader,
        sitePostback: sitePostback,
        createErrorObject: createErrorObject,
        createAjaxErrorObject: createAjaxErrorObject,
        convertToPositiveInteger: convertToPositiveInteger,
        htmlEscape: htmlEscape,
        transitionEndEventName: transitionEndEventName,
        getMatchStackSessionStorageKey: getMatchStackSessionStorageKey,
        getCovidHomeSessionStorageKey: getCovidHomeSessionStorageKey,
        getSettingsNotificationStorageKey: getSettingsNotificationStorageKey,
        findLatestExpiresAt: findLatestExpiresAt,
        parseQueryStringToArray: parseQueryStringToArray,
        getQueryStringItem: getQueryStringItem,
        arrayFirstOrDefault: arrayFirstOrDefault,
        isRushHour: isRushHour,
        rushHourEndsAt: rushHourEndsAt,
        getMatchstackEventsAdNoThanksClickedStorageKey: getMatchstackEventsAdNoThanksClickedStorageKey,
        isCommandKey: isCommandKey,
        detectIOS: detectIOS,
        detectAndroid: detectAndroid,
        switchToMobileView: switchToMobileView,
        clearObjectProperties: clearObjectProperties
    };
})(jQuery, moment);

PeopleMedia.Storage = (function () {
    "use strict";
    var Oid = "PeopleMedia.Storage";

    var storageAvailable = function(type) {
        try {
            var storage = window[type];
            var x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    };

    var localOrSessionStorage = storageAvailable("localStorage") ? window.localStorage : (storageAvailable("sessionStorage") ? window.sessionStorage : null);

    var isQuotaError = function(err) {
        return (err instanceof DOMException && (
            // everything except Firefox
            err.code === 22 ||
            // Firefox
            err.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            err.name === 'QuotaExceededError' ||
            // Firefox
            err.name === 'NS_ERROR_DOM_QUOTA_REACHED'));
    };

    var _loadFromStorage = function (storage, key) {
        var result = null;
        try {
            if (typeof (Storage) !== "undefined" && storage) {
                var value = storage.getItem(key);
                if (typeof (value) !== "undefined" && value !== null) {
                    result = JSON.parse(value);
                }
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { oid: Oid, method: "_loadFromStorage", key: key });
            throw ex;
        }
        return result;
    };

    var _saveToStorage = function (storage, key, value) {
        try {
            if (typeof (Storage) !== "undefined" && storage) {
                storage.setItem(key, JSON.stringify(value));
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { oid: Oid, method: "_saveToStorage", key: key, value: value });
            if (isQuotaError(ex)) {
                throw ex;
            }
        }
    };

    var _clearStorage = function (storage, keyPrefix, exceptPrefix) {
        try {
            if (typeof (Storage) !== "undefined" && storage) {
                var removeKeys = [];
                for (var i = 0; i < storage.length; i++) {
                    if (storage.key(i) &&
                        (!keyPrefix || storage.key(i).substring(0, keyPrefix.length) === keyPrefix) &&
                        (!exceptPrefix || storage.key(i).substring(0, exceptPrefix.length) !== exceptPrefix)) {
                        removeKeys.push(storage.key(i));
                    }
                }
                for (var k = 0; k < removeKeys.length; k++) {
                    storage.removeItem(removeKeys[k]);
                }
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { oid: Oid, method: "_clearStorage", keyPrefix: keyPrefix, exceptPrefix: exceptPrefix });
        }
    };

    var loadFromSessionStorage = function (key) {
        return _loadFromStorage(window.sessionStorage, key);
    };

    var saveToSessionStorage = function (key, value) {
        return _saveToStorage(window.sessionStorage, key, value);
    };

    var clearSessionStorage = function (keyPrefix, exceptPrefix) {
        return _clearStorage(window.sessionStorage, keyPrefix, exceptPrefix);
    };

    var loadFromStorage = function (key) {
        return _loadFromStorage(localOrSessionStorage, key);
    };

    var saveToStorage = function (key, value) {
        return _saveToStorage(localOrSessionStorage, key, value);
    };

    var clearStorage = function (keyPrefix, exceptPrefix) {
        return _clearStorage(localOrSessionStorage, keyPrefix, exceptPrefix);
    };

    return {
        loadFromSessionStorage: loadFromSessionStorage,
        saveToSessionStorage: saveToSessionStorage,
        clearSessionStorage: clearSessionStorage,
        loadFromStorage: loadFromStorage,
        saveToStorage: saveToStorage,
        clearStorage: clearStorage,
        isQuotaError: isQuotaError
    };
})();

PeopleMedia.Tracking = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Tracking";

    var AdTracking = function (eventName, featureName, onComplete) {
        try {
            var encodedMemberId = typeof (PeopleMedia.EncodedMemberId) !== "undefined" ? PeopleMedia.EncodedMemberId : null;
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
            var url = '/v3/ajax/TrackMobileSearchAd';
            var startTime = new Date().getTime();
            var timeoutValue = 60000;
            var ajaxData = {
                "EncodedMemberId": encodedMemberId,
                "EventType": eventName ? eventName : "",
                "FeatureName": featureName ? featureName : "",
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null
            };
            $jq.ajax({
                type: 'POST',
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "AdTracking", duration: duration }, jqXHR, textStatus, errorThrown, function () {
                    });
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            }).always(function () {
                if (onComplete && typeof (onComplete) === "function") {
                    onComplete();
                }
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { oid: Oid, method: "AdTracking" });
        }
    };

    var trackCustomMemberEventsComplete = function (err, response, params) {
        if (err || !response || response.status !== "Success") {
            // there was an error
            // console.log("trackCustomMemberEvents: error", err);
            if (params && typeof (params.onComplete) === "function") {
                params.onComplete(err, response, params);
            }
            return;
        }

        // console.log("trackMemberShown: success", response);
        if (params && typeof (params.onComplete) === "function") {
            params.onComplete(err, response, params);
        }
    };
    
    var trackCustomMemberEvents = function (params) {
        try {
            var validEvents = null;
            if (params && params.events) {
                validEvents = params.events.filter(function (ev) {
                    return ev !== null && typeof (ev) !== "undefined";
                });
            }
            if (!validEvents || validEvents.length === 0) {
                // do nothing if no events
                trackCustomMemberEventsComplete(null, { status: "Success" }, params);
                return;
            }
            var startTime = new Date().getTime();
            var url = "/v3/Ajax/TrackCustomMemberEvents";
            var timeoutValue = 60000;
            var ajaxData = {
                "events": validEvents
            };
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    trackCustomMemberEventsComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "trackCustomMemberEvents", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    trackCustomMemberEventsComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "trackCustomMemberEvents" });
            trackCustomMemberEventsComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
        }
    };
    
    // Track single event with callback
    var trackMemberEvent = function (eventName, callback) {
        var PageDefinitionId = null;
        if (typeof (PeopleMedia.PageDefinitionId) !== "undefined") {
            PageDefinitionId = PeopleMedia.PageDefinitionId;
        }

        trackCustomMemberEvents({
            events: [
                {
                    otherMemberProfileId: null,
                    eventName: eventName,
                    featureSource: "Match",
                    pageId: PageDefinitionId
                }
            ],
            onComplete: callback
        });
    };

    var trackComplete = function (err, response, params) {
        if (err || !response || response.status !== "Success") {
            // there was an error
            if (params && typeof (params.onComplete) === "function") {
                params.onComplete(err, response, params);
            }
            return;
        }

        if (params && typeof (params.onComplete) === "function") {
            params.onComplete(err, response, params);
        }
    };

    // Call as member is being rendered into view
    var trackMemberShown = function (params) {
        try {
            var startTime = new Date().getTime();
            var url = "/v3/Ajax/TrackMemberShown";
            var timeoutValue = 60000;
            var ajaxData = {
                "otherMemberPublicGuid": params.otherMemberPublicGuid,
                "otherMemberProfileId": params.otherMemberProfileId,
                "eventActionPrefix": params.eventActionPrefix,
                "viewLocation": params.viewLocation,
                "featureSource": params.featureSource,
                "displayModule": params.displayModule,
                "matchedUserType": params.matchedUserType,
                "presentationId": params.presentationId,
                "searchGuid": params.searchGuid,
                "recordGuid": params.recordGuid,
                "PageId": PeopleMedia.PageDefinitionId
            };
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    trackComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "trackMemberShown", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    trackComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "trackMemberShown" });
            trackComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
        }
    };

    var trackSwitchDeviceType = function (params) {
        try {
            var startTime = new Date().getTime();
            var url = "/v3/Ajax/TrackSwitchDeviceType";
            var timeoutValue = 2000;
            var ajaxData = {
                "eventName": params.eventName,
                "previousValue": params.previousValue,
                "featureSource": params.featureSource,
                "PageId": PeopleMedia.PageDefinitionId
            };
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    trackComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "trackSwitchDeviceType", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    trackComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "trackSwitchDeviceType" });
            trackComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
        }
    };

    var updateClickThroughFlow = function (params) {
        try {
            var startTime = new Date().getTime();
            var url = "/v3/Ajax/UpdateClickThroughFlow";
            var timeoutValue = 5000;
            var path = params && params.path ? params.path : "";
            var page = params && typeof (params.page) === "number" ? params.page : null;
            var pageId = params && typeof (params.pageId) === "number" ? params.pageId : PeopleMedia.PageDefinitionId;
            var ajaxData = {
                "Path": path,
                "Page": page,
                "PageId": pageId
            };
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    trackComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "trackSwitchDeviceType", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    trackComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "updateClickThroughFlow" });
            trackComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
        }
    };

    return {
        trackMemberShown: trackMemberShown,
        trackCustomMemberEvents: trackCustomMemberEvents,
        AdTracking: AdTracking,
        trackMemberEvent: trackMemberEvent,
        trackSwitchDeviceType: trackSwitchDeviceType,
        updateClickThroughFlow: updateClickThroughFlow
    };
})(jQuery);

PeopleMedia.LogAjaxError = function (event, jqXHR, ajaxSettings, thrownError) {
    "use strict";
    if (thrownError !== "timeout" && (jqXHR.readyState == 0 || jqXHR.status == 0)) {
        $jq(document).one("ajaxError", PeopleMedia.LogAjaxError);
        return;
    }

    if (ajaxSettings && ajaxSettings.url) {
        if (ajaxSettings.url.indexOf("/v3/log/logexception") >= 0 ||
            ajaxSettings.url.indexOf("/v3/ajax/RefreshSession") >= 0 ||
            ajaxSettings.url.indexOf("/keepalive.cfm") >= 0) {
            $jq(document).one("ajaxError", PeopleMedia.LogAjaxError);
            return;
        }
    }

    if (ajaxSettings && ajaxSettings.suppressErrors) {
        $jq(document).one("ajaxError", PeopleMedia.LogAjaxError);
        return;
    }

    PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", ajaxSettings.url, { settings: ajaxSettings }, jqXHR, null, thrownError, function() {
        $jq(document).one("ajaxError", PeopleMedia.LogAjaxError);
    });
};
(function () {
    "use strict";
    $jq.ajaxSetup({ timeout: 60000 });
    //$jq(document).one("ajaxError", PeopleMedia.LogAjaxError);
})();

PeopleMedia.Utility = (function ($jq) {
    "use strict";
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    var debounce = function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };

    // detect if passive events are supported by the browser
    var passiveSupported = false;
    var isPassiveEventListenerOptionSupported = function () {
        try {
            const options = {
                get passive() { // This function will be called when the browser
                    //   attempts to access the passive property.
                    passiveSupported = true;
                    return false;
                }
            };

            window.addEventListener("test", null, options);
            window.removeEventListener("test", null, options);
        } catch (err) {
            passiveSupported = false;
        }

        return passiveSupported;
    };

    // used by enable/disable scrolling functions to maintain y position on page
    var scrollPosition = 0;

    var enableBodyScrolling = function () {
        $jq("body").removeClass("no-scroll").removeClass("no-scroll-fixed-position").css("top", "auto");
        window.scrollTo(0, scrollPosition);
    };

    var disableBodyScrolling = function (useFixedPositioning) {
        scrollPosition = window.pageYOffset;
        if (useFixedPositioning) {
            $jq("body").addClass("no-scroll-fixed-position").css("top", "-" + scrollPosition + "px");
        } else {
            $jq("body").addClass("no-scroll").css("top", "-" + scrollPosition + "px");
        }
        
    };

    // use this to determine if an element is fully in view
    var isElementInViewport = function (el) {
        // Special bonus for those using jQuery
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }

        var rect = el.getBoundingClientRect();

        return (
            (rect.top >= 0 || rect.height > window.innerHeight) &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    };

    // scrolls the page down to bring the bottom of the element into the visible part of the page
    var scrollDownElementIntoView = function(element) {
        var pageTop = $jq(window).scrollTop();
        var pageBottom = pageTop + $jq(window).height();
        var elementTop = $jq(element).offset().top;
        var elementBottom = elementTop + $jq(element).height();

        // only scroll if element is currently not in view
        if (pageBottom < elementBottom) {
            var amountToScroll = Math.abs(pageBottom - elementBottom);
            $jq("html, body").animate({ scrollTop: pageTop + amountToScroll }, 600);
        }
    };

    return {
        debounce: debounce,
        enableBodyScrolling: enableBodyScrolling,
        disableBodyScrolling: disableBodyScrolling,
        scrollDownElementIntoView: scrollDownElementIntoView,
        isElementInViewport: isElementInViewport,
        isPassiveEventListenerOptionSupported: isPassiveEventListenerOptionSupported
    };
})(jQuery);

PeopleMedia.Cookies = (function () {
    "use strict";

    var setCookie = function(name, value, days, useDomain) {
        var expires = "";
        var domain = "";
        if (useDomain && PeopleMedia.Domain) {
            domain = "; domain=." + PeopleMedia.Domain.toLowerCase();
        }
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + domain + expires + "; path=/; secure";
    };

    var getCookie = function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    };

    var eraseCookie = function (name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    };

    var parseKeyValueString = function (val) {
        var hash = [];
        try {
            var pieces = val.split('&');
            for (var i = 0; i < pieces.length; i++) {
                var pair = pieces[i].split('=');
                if (pair.length > 0) {
                    var key = decodeURIComponent(pair[0]);
                    var str = decodeURIComponent(pair[1]);
                    hash[key] = str;
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        return hash;
    };

    return {
        setCookie: setCookie,
        getCookie: getCookie,
        eraseCookie: eraseCookie,
        parseKeyValueString: parseKeyValueString
    };
})();

PeopleMedia.Timer = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Timer";
    var logoutWarningMinutes = 15;
    var logoutWarningTimer = null;
    var StartCountdownTimer = function (minutes) {
        if (!PeopleMedia.PersistentLoginEnabled && PeopleMedia.IsLoggedIn) {
            if (minutes) {
                logoutWarningMinutes = minutes;
            }
            if (logoutWarningTimer) {
                clearTimeout(logoutWarningTimer);
            }

            logoutWarningTimer = setTimeout(ShowLogoutWarning, logoutWarningMinutes * 60000 - 10000);
        }
    };

    var autoLogoutInterval = null;
    var finalCountdown = 0;
    var ShowLogoutWarning = function () {
        if (!PeopleMedia.PersistentLoginEnabled && PeopleMedia.IsLoggedIn) {
            var html =
                "<div class=\"pop-up\">" +
                "<div class=\"logout-warning\">" +
                "<h3>Keep it up, there are more great connections waiting for you! You&rsquo;ve been inactive and will be logged out in <span id=\"timeBeforeLogoutSpan\">5:00</span>.</h3>" +
                "<div><a href=\"javascript:void(0);\" class=\"button PopupOkButton\" id=\"autoLogoutStayLoggedInButton\" title=\"Keep Me Logged In!\">Stay Logged In</a><a href=\"/v3/logout\" class=\"button grey\" title=\"Log me out\">Logout</a></div>" +
                "</div>" +
                "</div>";
            $jq("#PopupDisplayAreaDiv").empty().html(html);
            $jq("#autoLogoutStayLoggedInButton").on("click", function () {
                ExtendSession();
                ClosePopup();
            });
            $jq("#PopupDisplayAreaDiv").show();
            autoLogoutInterval = setInterval(UpdateFinalCountdown, 1000);
            finalCountdown = 300;
        }
    };

    var AbortLogoutTimer = function () {
        if (!PeopleMedia.PersistentLoginEnabled && PeopleMedia.IsLoggedIn) {
            if (logoutWarningTimer) {
                clearTimeout(logoutWarningTimer);
                logoutWarningTimer = null;
            } else {
                setTimeout(AbortLogoutTimer, 5000);
            }
        }
    };

    var UpdateFinalCountdown = function () {
        if (!PeopleMedia.PersistentLoginEnabled && PeopleMedia.IsLoggedIn) {
            finalCountdown--;
            if (finalCountdown < 0) {
                clearInterval(autoLogoutInterval);
                Logout();
                return;
            }
            var totalSeconds = finalCountdown;
            var minutes = Math.floor(totalSeconds / 60);
            var seconds = totalSeconds % 60;
            var padding = "";
            if (seconds < 10) {
                padding = "0";
            }
            $jq("#timeBeforeLogoutSpan").html(minutes + ":" + padding + seconds);
        }
    };

    var Logout = function () {
        location.href = "/v3/logout/";
    };

    var ClosePopup = function () {
        if (!PeopleMedia.PersistentLoginEnabled && PeopleMedia.IsLoggedIn) {
            clearInterval(autoLogoutInterval);
            $jq("#PopupDisplayAreaDiv").hide();
            PeopleMedia.RefreshSession();
            StartCountdownTimer();
        } else {
            $jq("#PopupDisplayAreaDiv").hide();
        }
    };

    var Init = function () {
        if (!PeopleMedia.PersistentLoginEnabled && PeopleMedia.IsLoggedIn) {
            StartCountdownTimer(15);
        }
    };

    var sessionMinutesRemaining = 20;
    var sessionInterval = null;
    var minutesSinceLastActivity = 0;
    var ExtendSession = function (totalMinutes) {
        if (!PeopleMedia.PersistentLoginEnabled && PeopleMedia.IsLoggedIn) {
            if (totalMinutes) {
                sessionMinutesRemaining = totalMinutes;
                StartCountdownTimer(totalMinutes - 5);
            }
            if (sessionInterval == null) {
                sessionInterval = setInterval(ExtendSession, 60000);
            } else {
                sessionMinutesRemaining--;
                minutesSinceLastActivity++;
                if (minutesSinceLastActivity + sessionMinutesRemaining > 20 && minutesSinceLastActivity > 16) {
                    PeopleMedia.RefreshSession();
                }
            }
        }
    };
    return {
        Init: Init,
        StartCountdownTimer: StartCountdownTimer,
        AbortLogoutTimer: AbortLogoutTimer
    };
})(jQuery);

// has dependencies on jQuery
PeopleMedia.ReportConcern = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.ReportConcern";

    var racParams = null;
    var maximumCommentLength = 255;
    var shouldRefreshPage = false;
    var usesInputEvent = false;

    var $popupDisplayAreaDiv = $jq();
    var $reportConcernMain = $jq();
    var $reportConcernPanel = $jq();
    var $reportConcernPanelConcern = $jq();
    var $reportConcernPanelComment = $jq();
    var $reportConcernPanelThanks = $jq();
    var $concernType = $jq();
    var $blockedCheckbox = $jq();
    var $blockedCheckboxContainer = $jq();
    var $concernButton = $jq();
    var $commentsButton = $jq();
    var $comments = $jq();
    var $optionalComments = $jq();
    var $charactersRemainingValue = $jq();
    var $userBlocked = $jq();

    var _isBlocked = false;

    // underage requires blocking
    var requiresBlock = function (concern) {
        return concern === 9;
    };

    // underage skips over comments
    var allowsComments = function (concern) {
        return concern !== 9;
    };

    // need additional info for inappropriate messages, offline behavior, someone is in danger
    var requiresComments = function (concern) {
        return concern === 1 || concern === 7 || concern === 10;
    };

    var updateBlockedUserSelection = function (concern) {
        if (requiresBlock(concern)) {
            var isChecked = $blockedCheckbox.prop("checked");
            if (!isChecked) {
                $blockedCheckbox.prop("checked", true);
            }
            $blockedCheckbox.prop("disabled", true);
            $blockedCheckboxContainer.addClass("disabled");
        } else {
            $blockedCheckbox.prop("disabled", false);
            $blockedCheckboxContainer.removeClass("disabled");
        }
    };

    var getBlockedUserSelection = function () {
        return $blockedCheckbox.prop("checked");
    };

    var getSelectedConcern = function () {
        var concern = null;
        var $selected = $concernType.filter(".selected");
        if ($selected.length > 0) {
            var data = parseInt($selected.first().data("concern"), 10);
            if (!isNaN(data)) {
                concern = data;
            }
        }
        return concern;
    };

    var getComments = function () {
        return ($comments.val() || "").trim();
    };

    var closePopups = function () {
        PeopleMedia.Popups.ClosePopups();
        if (shouldRefreshPage) {
            setTimeout(function () {
                location.reload();
            }, 0);
            return;
        }

        if (typeof (racParams.onComplete) === "function") {
            racParams.onComplete({
                blocked: _isBlocked
            });
        }
    };

    var doneClick = function (event) {
        var $this = $jq(this);
        // invert button colors temporarily
        $this.addClass("clicked");
        setTimeout(function () {
            $this.removeClass("clicked");
            closePopups();
            if (shouldRefreshPage) {
                setTimeout(function () {
                    location.reload();
                }, 0);
                return;
            }

            if (typeof (racParams.onComplete) === "function") {
                racParams.onComplete({
                    blocked: _isBlocked
                });
            }
        }, 150);
    };

    var showPanelConcern = function () {
        $popupDisplayAreaDiv.show();
        $reportConcernPanel.not($reportConcernPanelConcern).addClass("hidden");
        $reportConcernPanelConcern.removeClass("hidden");
    };

    var showPanelComment = function () {
        var concern = getSelectedConcern();
        var commentsRequired = requiresComments(concern);
        if (!commentsRequired) {
            $optionalComments.removeClass("hidden");
            $commentsButton.removeClass("disabled");
        } else {
            $optionalComments.addClass("hidden");
            $commentsButton.addClass("disabled");
        }

        $popupDisplayAreaDiv.show();
        $reportConcernPanel.not($reportConcernPanelComment).addClass("hidden");
        $reportConcernPanelComment.removeClass("hidden");
    };

    var showPanelThanks = function () {
        $popupDisplayAreaDiv.show();
        $reportConcernPanel.not($reportConcernPanelThanks).addClass("hidden");
        $reportConcernPanelThanks.removeClass("hidden");
    };

    var submitReportConcern = PeopleMedia.Utility.debounce(function (params) {
        try {
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
            var startTime = new Date().getTime();
            var url = "/v3/Ajax/ReportConcern";
            var timeoutValue = 60000;
            var ajaxData = {
                "EncodedMemberId": params.encodedMemberId,
                "PublicMemberId": params.publicMemberId,
                "Message": params.comments,
                "SkipCSSVerif": "HTMLEditor",
                "BlockUser": params.blockUser,
                "ConcernId": params.concernType,
                "Username": params.username,
                "QueryString": window.location.search,
                "MediaId": params.mediaId,
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null
            };
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    submitReportConcernComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "submitReportConcern", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    submitReportConcernComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "submitReportConcern" });
            submitReportConcernComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
        }
    }, 1000, true);

    var submitReportConcernComplete = function (err, response, params) {
        var success = (!err && response && response.status === "Success");

        if (response && response.blocked) {
            if (success) {
                $userBlocked.removeClass("hidden");
                _isBlocked = true;
            }
            // record blocked member to match stack if present
            PeopleMedia.Popups.RecordBlockedMemberToMatchStack(params.encodedMemberId, params.publicMemberId);
            shouldRefreshPage = params.refreshPage === true;
        }

        // session expired might redirect
        if (response && response.redirect) {
            location.href = response.redirect;
            return;
        }

        showPanelThanks();
    };

    var selectConcernClick = function (event) {
        var $this = $jq(this);
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        $this.addClass("selected");
        $concernType.not($this).removeClass("selected");
        $concernButton.removeClass("disabled");

        var concern = getSelectedConcern();
        var $buttonText = $concernButton.find(".button-text");
        if ($buttonText.length > 0) {
            $buttonText.text(allowsComments(concern) ? "Next" : "Send");
        }
        updateBlockedUserSelection(concern);
    };

    var reportConcernNextClick = function(event) {
        var $this = $jq(this);
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        var concern = getSelectedConcern();
        var blockUser = requiresBlock(concern) || getBlockedUserSelection();
        var isCommentsPanel = $this.closest(".report-a-concern-panel").hasClass("panel-comment");
        var comments = getComments();

        if (!$this.hasClass("disabled") && concern > 0) {
            // invert button colors temporarily
            $this.addClass("clicked");
            setTimeout(function() {
                $this.removeClass("clicked");
                if (!isCommentsPanel && allowsComments(concern)) {
                    showPanelComment();
                    return;
                }
                if (isCommentsPanel && requiresComments(concern) && comments.length === 0) {
                    return;
                }

                submitReportConcern({
                    encodedMemberId: racParams.encodedMemberId,
                    username: racParams.username,
                    publicMemberId: racParams.publicMemberId,
                    concernType: concern,
                    blockUser: blockUser,
                    comments: comments,
                    mediaId: racParams.mediaId,
                    isTodaysMatches: racParams.isTodaysMatches,
                    refreshPage: racParams.refreshPage
                });
            }, 150);
        }
    };

    var updateTextCounter = function($target) {
        var newText;
        var text = $target.val() || "";
        var chars = text.length;
        if (chars > maximumCommentLength) {
            newText = text.substr(0, maximumCommentLength);
            $target.val(newText);
        }
        var remain = maximumCommentLength - chars;
        if (remain < 0) {
            remain = 0;
        }
        $charactersRemainingValue.text(remain);
        var trimmedText = getComments();
        if (!trimmedText && requiresComments(getSelectedConcern())) {
            $commentsButton.addClass("disabled");
        } else {
            $commentsButton.removeClass("disabled");
        }
    };

    var commentOnKeyUp = function (event) {
        var $target = $jq(event.currentTarget);
        var key = event.which;
        if (!PeopleMedia.isCommandKey(key)) {
            updateTextCounter($target);
        }
    };

    var commentOnPaste = function (event) {
        var $target = $jq(this);
        setTimeout(function () {
            updateTextCounter($target);
        }, 0);
    };

    var commentOnChange = function (event) {
        var $target = $jq(this);
        updateTextCounter($target);
    };

    var commentOnInput = function (event) {
        var $target = $jq(this);
        usesInputEvent = true;
        // don't use keyup if the browser supports input event
        $comments.off("keyup.pm");
        updateTextCounter($target);
    };

    var touchMove = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };

    var init = function (params) {
        // params.encodedMemberId
        // params.username
        // params.publicMemberId
        // params.mediaId
        // params.isTodaysMatches
        // params.refreshPage
        shouldRefreshPage = false;
        racParams = params;
        _isBlocked = false;

        if (typeof (params) !== "object" || (!params.encodedMemberId && !params.publicMemberId)) {
            PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
            return;
        }

        var html =
            "<div class=\"report-a-concern\">" +
            "<div class=\"report-a-concern-container\">" +
            "<div class=\"report-a-concern-close-modal-btn\"><i class=\"bold\"></i></div>" +
            "<div class=\"report-a-concern-panel panel-concern\">" +
            "<h1>Report</h1>" +
            "<p class=\"instructions select-reason\">Please select the reason:</p>" +
            "<div class=\"concern-items\">" +
            "<div class=\"concern-type top-separator\" data-concern=\"2\"><div class=\"concern-type-inner\"><div class=\"icon-wrapper\"><div class=\"icon rac-fake-profile-spam-icon\"></div></div><span>Fake profile/spam</span></div></div>" +
            "<div class=\"concern-type top-separator\" data-concern=\"1\"><div class=\"concern-type-inner\"><div class=\"icon-wrapper\"><div class=\"icon rac-inappropriate-messages-icon\"></div></div><span>Inappropriate messages</span></div></div>" +
            "<div class=\"concern-type top-separator\" data-concern=\"3\"><div class=\"concern-type-inner\"><div class=\"icon-wrapper\"><div class=\"icon rac-inappropriate-photos-icon\"></div></div><span>Inappropriate photos</span></div></div>" +
            "<div class=\"concern-type top-separator\" data-concern=\"8\"><div class=\"concern-type-inner\"><div class=\"icon-wrapper\"><div class=\"icon rac-inappropriate-bio-icon\"></div></div><span>Inappropriate bio</span></div></div>" +
            "<div class=\"concern-type top-separator\" data-concern=\"9\"><div class=\"concern-type-inner\"><div class=\"icon-wrapper\"><div class=\"icon rac-underage-user-icon\"></div></div><span>Underage user</span></div></div>" +
            "<div class=\"concern-type top-separator\" data-concern=\"7\"><div class=\"concern-type-inner\"><div class=\"icon-wrapper\"><div class=\"icon rac-offline-behavior-icon\"></div></div><span>Offline behavior</span></div></div>" +
            "<div class=\"concern-type top-separator bottom-separator\" data-concern=\"10\"><div class=\"concern-type-inner\"><div class=\"icon-wrapper\"><div class=\"icon rac-someone-in-danger-icon\"></div></div><span>Someone is in danger</span></div></div>" +
            "<div class=\"report-a-concern-block-user\">" +
            "<div class=\"concern-type-inner\"><label class=\"rac-checkbox-container\"><input name=\"ReportConcernBlockUserCheckbox\" type=\"checkbox\" value=\"1\" checked><span class=\"checkmark\"></span></label><span class=\"blocktext\">Block this user</span></div>" +
            "</div>" +
            "</div>" +
            "<a class=\"report-a-concern-button disabled\" href=\"javascript:void(0);\">" +
            "<span class=\"button-text\">Next</span>" +
            "</a>" +
            "</div>" +
            "<div class=\"report-a-concern-panel panel-comment hidden\">" +
            "<h1>Report</h1>" +
            "<p class=\"instructions provide-comments\">Please provide comments<span class=\"optional-comments hidden\"> (optional)</span>.</p>" +
            "<div class=\"comments-container\">" +
            "<textarea maxlength=\"" + maximumCommentLength + "\" placeholder=\"Type your comments here...\"></textarea>" +
            "</div>" +
            "<div class=\"characters-remaining\"><span>Characters Remaining: </span><span class=\"characters-remaining-value\">" + maximumCommentLength + "</span>" +
            "</div>" +
            "<a class=\"report-a-concern-button disabled\" href=\"javascript:void(0);\">" +
            "<span class=\"button-text\">Send</span>" +
            "</a>" +
            "<a class=\"report-a-concern-link\" href=\"javascript:void(0);\">" +
            "<span class=\"link-text\">Cancel</span>" +
            "</a>" +
            "</div>" +
            "<div class=\"report-a-concern-panel panel-thanks hidden\">" +
            "<h1>Thank You</h1>" +
            "<p class=\"thanks-text\">Your concern is taken very seriously and may require a few days to fully investigate.</p>" +
            "<p class=\"blockeduser hidden\">This user has been blocked.</p>" +
            "<a class=\"report-a-concern-button\" href=\"javascript:void(0);\">" +
            "<span class=\"button-text\">Done</span>" +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>";

        $popupDisplayAreaDiv = $jq("#PopupDisplayAreaDiv");
        $popupDisplayAreaDiv.empty().html(html);

        $reportConcernMain = $jq(".report-a-concern");
        $reportConcernPanel = $jq(".report-a-concern-panel");
        $reportConcernPanelConcern = $reportConcernPanel.filter(".panel-concern");
        $reportConcernPanelComment = $reportConcernPanel.filter(".panel-comment");
        $reportConcernPanelThanks = $reportConcernPanel.filter(".panel-thanks");

        // all panels
        $reportConcernMain.find(".report-a-concern-close-modal-btn").on("click", closePopups);
        $reportConcernMain.on("touchmove", touchMove);

        // report concern panel
        $concernType = $reportConcernPanelConcern.find(".concern-type");
        $blockedCheckboxContainer = $reportConcernPanelConcern.find(".rac-checkbox-container");
        $blockedCheckbox = $blockedCheckboxContainer.find("input[type='checkbox']");
        $concernButton = $reportConcernPanelConcern.find(".report-a-concern-button");

        $reportConcernPanelConcern.on("click", ".concern-type", selectConcernClick);
        $concernButton.on("click", reportConcernNextClick);

        // comments panel
        $comments = $reportConcernPanelComment.find(".comments-container textarea");
        $charactersRemainingValue = $reportConcernPanelComment.find(".characters-remaining-value");
        $commentsButton = $reportConcernPanelComment.find(".report-a-concern-button");
        $optionalComments = $reportConcernPanelComment.find(".optional-comments");

        $comments.on("keyup.pm", commentOnKeyUp)
            .on("paste.pm", commentOnPaste)
            .on("change.pm", commentOnChange)
            .on("input.pm", commentOnInput);

        $commentsButton.on("click", reportConcernNextClick);
        $reportConcernPanelComment.find(".report-a-concern-link").on("click", closePopups);

        // thanks panel
        $userBlocked = $reportConcernPanelThanks.find(".blockeduser");
        $reportConcernPanelThanks.find(".report-a-concern-button").on("click", doneClick);

        showPanelConcern();
    };

    return {
        init: init
    };
})(jQuery);

// has dependencies on jQuery and moment.js
PeopleMedia.Popups = (function ($jq, moment) {
    "use strict";
    var Oid = "PeopleMedia.Popups";

    var _closeCallBack;
    var setOnCloseCallback = function(callBack) {
        _closeCallBack = callBack;
    };

    var CheckProfileBouncer = function (onComplete) {
        // check if in a/b test and if missing photos
        if (typeof (PeopleMediaConfig) !== "undefined" &&
            PeopleMediaConfig !== null &&
            typeof (PeopleMediaConfig.Configuration) !== "undefined" &&
            PeopleMediaConfig.Configuration !== null &&
            PeopleMediaConfig.Configuration.isProfileBouncerEnabled &&
            !PeopleMediaConfig.Configuration.hasPhotos
        ) {
            // user is missing photos - show them profile bouncer
            ShowProfileBouncer();
        } else {
            if (onComplete && typeof (onComplete) === "function") {
                onComplete();
            }
        }
    };

    var profileBouncerCloseClicked = function (e) {
        ClosePopups();
        PeopleMedia.Utility.enableBodyScrolling();
    };

    var profileBouncerCompleteProfileClicked = PeopleMedia.Utility.debounce(function (e) {
        $jq(".profile-bouncer-container .complete-profile-btn").addClass("clicked");

        // navigate to edit profile page
        window.location = "/v3/Profile/EditMyProfile/";
    }, 800, true);

    var ShowProfileBouncer = function () {
        ClosePopups();
        PeopleMedia.Utility.disableBodyScrolling(false);
        var html =
            "<div class=\"profile-bouncer-popup\">" +
            "    <div class=\"profile-bouncer-container\">" +
            "        <img class=\"close-btn\" src=\"" + PeopleMediaConfig.Configuration.commonImagePath + "/close.svg\" >" +
            "        <img class=\"header-image\" src=\"" + PeopleMediaConfig.Configuration.themeImagePath + "/profile-bouncer.svg\" >" +
            "        <div class=\"paragraph-section-1\">" +
            "            Your profile does not adhere to our community guidelines possibly because you did not upload a clear photo or write a legitimate response." +
            "        </div>" +
            "        <div class=\"paragraph-section-2\">" +
            "            You must complete your profile before you can reach out to our members." +
            "        </div>" +
            "        <div class=\"complete-profile-btn\" role=\"button\" tabindex=\"0\">Complete Profile</div>" +
            "    </div>" +
            "</div>";
        $jq("#PopupDisplayAreaDiv").html(html);
        $jq(".profile-bouncer-container .close-btn").on("click", profileBouncerCloseClicked);
        $jq(".profile-bouncer-container .complete-profile-btn").on("click", profileBouncerCompleteProfileClicked);

        // check if header is present (popup shouldn't overlap header bar)
        if (!PeopleMedia.Utility.isElementInViewport($jq(".layout-container-header"))) {
            $jq(".profile-bouncer-popup").addClass("full-screen");
        }

        $jq("#PopupDisplayAreaDiv").show();
        $jq(".profile-bouncer-container").show();
    };

    var AjaxErrorMessage = function () {
        return "Whoops... something's amiss. Please try your request again. If you see this error repeatedly, please refresh the page.";
    };

    var ValidateResponse = function (response) {
        if (response) {
            if (response.redirect) {
                location.href = response.redirect;
                return false;
            }
            if (response.status === "Success") {
                return true;
            } else if (response.status !== "Success") {
                ShowGenericMessage(response.message);
                return false;
            }
        }
        return false;
    };

    var ShowGenericMessage = function (message, callback) {
        ClosePopups();
        var html =
            "<div class=\"pop-up\">" +
            "<div class=\"generic-message\">" +
            "<i class=\"img x\"  title=\"Close\"></i>" +
            "<img role=\"presentation\" class=\"close-btn x\" title=\"Close\" src=\"" + PeopleMediaConfig.Configuration.commonImagePath + "/close.svg\" />" +
            "<h3 class=\"title-text\"></h3>" +
            "<div class=\"cta-section\"><a href=\"javascript:void(0);\" class=\"button ok-cta\" title=\"OK\">OK</a></div>" +
            "</div>" +
            "</div>";
        $jq("#PopupDisplayAreaDiv").empty().html(html);
        if (message) {
            $jq(".generic-message h3").html(message);
        } else {
            $jq(".generic-message h3").html("There was an unexpected error. Please try again.");
        }
        $jq(".generic-message .x").on("click", function () {
            ClosePopups();
        });
        $jq(".generic-message > div > a").on("click", function () {
            ClosePopups();
            if (callback)
                callback();
        });
        $jq("#PopupDisplayAreaDiv").show();
    };

    var LongMessage = function () {
        var html =
            "<div class=\"pop-up\">" +
            "<div class=\"long-message\">" +
            "<i class=\"img x\"  title=\"Close\"></i>" +
            "<h3>Oops...</h3>" +
            "<div>You&rsquo;ve reach the maximum allowed length for your message. Click Continue to wrap up your message and send.</div>" +
            "<div><a href=\"javascript:void(0);\" class=\"button\" title=\"Continue\">Continue</a></div>" +
            "</div>" +
            "</div>";
        setTimeout(ClosePopups, 5000);
        $jq("#PopupDisplayAreaDiv").empty().html(html);
        $jq(".long-message .x").on("click", ClosePopups);
        $jq(".long-message .button").on("click", ClosePopups);
        $jq("#PopupDisplayAreaDiv").show();
    };

    var LearnMore = function (sitepath) {
        var html =
            "<div class=\"pop-up\">" +
            "<div class=\"learn-more-search\">" +
            "<i class=\"img x\"  title=\"Close\"></i>" +
            "<h3>Extended Network and Public Availability of User Profile Information</h3>" +
            "<div>Opted-in members can appear on external search results for " + sitepath + " as well as on " + sitepath + " partner channels for up to two weeks at a time (this can be anywhere on the " + sitepath + " website or in any section of our partners\" websites, including the homepage).</div>" +
            "</div>" +
            "</div>";
        $jq("#PopupDisplayAreaDiv").empty().html(html);
        $jq(".learn-more-search .x").on("click", ClosePopups);
        $jq("#PopupDisplayAreaDiv").show();
    };

    var ShareProfile = function (params) {
        var html =
            "<div class=\"pop-up\">" +
            "<div class=\"share-profile\">" +
            "<i class=\"img x\"  title=\"Close\"></i>" +
            "<img role=\"presentation\" class=\"close-btn x\" title=\"Close\" src=\"" + PeopleMediaConfig.Configuration.commonImagePath + "/close.svg\" />" +
            "<h3 id=\"share_profile_error\" style=\"display:none;\"></h3>" +
            "<h3 class=\"title-text\">Do you know someone who might be interested in this member&rsquo;s profile?</h3>" +
            "<div><span class=\"email-label\">Your friend&rsquo;s email:</span><div><input id=\"ShareProfileEmailTextbox\" type=\"text\" ></div></div>" +
            "<div class=\"message-container\"><span class=\"message-label\">Message to friend (or type your own):</span><div><textarea id=\"ShareProfileTextArea\">Hey, I came across this profile on " + PeopleMedia.Domain + " and thought you might be interested.</textarea></div></div>" +
            "<div class=\"cta-section\"><a class=\"cancel-cta\" href=\"javascript:void(0);\">Cancel</a><a href=\"javascript:void(0);\" id=\"ShareProfileSendButton\" class=\"button share-cta\" title=\"Share\">Share</a></div>" +
            "</div>" +
            "</div>";
        $jq("#PopupDisplayAreaDiv").empty().html(html);
        $jq(".share-profile .x").on("click", ClosePopups);
        $jq(".share-profile > div:last-child > a:first-child").on("click", ClosePopups);
        $jq("#PopupDisplayAreaDiv").show();
        PeopleMedia.AccessibilityHelper.removeOutlineOnClick($jq(".share-profile"));
        $jq("#ShareProfileSendButton").on("click", function () {
            ShareProfileComplete(params);
        });
    };

    var ShareProfileComplete = function (params) {
        var encodedMemberId = params.encodedMemberId;
        var username = params.username;
        var publicMemberId = params.publicMemberId;

        $jq("#ShareProfileSendButton").off();
        $jq("#share_profile_error").hide();
        $jq(".share-profile").removeClass("share-profile-invalid-email");

        var email = $jq("#ShareProfileEmailTextbox").val();
        var message = $jq("#ShareProfileTextArea").val();
        var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
        var startTime = new Date().getTime();
        var url = "/v3/Ajax/ShareProfile";
        var timeoutValue = 60000;
        var ajaxData = {
            "EncodedMemberId": encodedMemberId,
            "Username": username,
            "PublicMemberId": publicMemberId,
            "Message": message,
            "EmailAddress": email,
            "PageDefinitionId": pageDefinitionId,
            "PageDefinitionSubId": null,
            "PageNumber": null,
            "PageDefinitionView": null
        };
        $jq.ajax({
            headers: PeopleMedia.sitePostbackHeader(),
            type: "POST",
            url: url,
            data: JSON.stringify(ajaxData),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function (response) {
                if (response.message == "Invalid Email address.") {
                    $jq(".share-profile").addClass("share-profile-invalid-email");
                    $jq("#share_profile_error").show();
                    $jq("#share_profile_error").html(response.message);

                    $jq("#ShareProfileSendButton").on("click", function () {
                        ShareProfileComplete({
                            encodedMemberId: encodedMemberId,
                            username: username,
                            publicMemberId: publicMemberId
                        });
                    });
                } else if (ValidateResponse(response)) {
                    ClosePopups();
                    ShowGenericMessage(response.message);
                    setTimeout(ClosePopups, 5000);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (errorThrown === "timeout" || (jqXHR.readyState !== 0 && jqXHR.status !== 0)) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    // ShowGenericMessage(AjaxErrorMessage());
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "ShareProfileComplete", duration: duration }, jqXHR, textStatus, errorThrown, function () {
                    });
                }
            },
            async: true,
            cache: false,
            timeout: timeoutValue,
            suppressErrors: true
        });
    };

    var BlockUser = function (params) {
        var username = params.username;
        var html =
            "<div class=\"pop-up\">" +
            "<div class=\"block-user\">" +
            "<i class=\"img x\"  title=\"Close\"></i>" +
            "<img role=\"presentation\" class=\"close-btn x\" title=\"Close\" src=\"" + PeopleMediaConfig.Configuration.commonImagePath + "/close.svg\" />" +
            "<h3 class=\"title-text\">Are you sure you want to block <span>this user</span>?</h3>" +
            "<div class=\"sub-text\">If you block this user, they will NOT be able to contact you.</div>" +
            "<div class=\"cta-section\"><a class=\"cancel-cta\" href=\"javascript:void(0);\">Cancel</a><a class=\"block-cta button\" href=\"javascript:void(0);\" title=\"Block User\">Block User</a></div>" +
            "</div>" +
            "</div>" +
            "<div class=\"pop-up confirm hidden\">" +
            "<div class=\"block-user-confirm\">" +
            "<i class=\"img x\"  title=\"Close\"></i>" +
            "<img role=\"presentation\" class=\"close-btn x\" title=\"Close\" src=\"" + PeopleMediaConfig.Configuration.commonImagePath + "/close.svg\" />" +
            "<h3 class=\"title-text\"><span>This user</span> has been blocked.</h3>" +
            "<a class=\"done-cta x\" href=\"javascript:void(0);\">Done</a>" +
            "<div></div>" +
            "</div>" +
            "</div>";
        $jq("#PopupDisplayAreaDiv").empty().html(html);
        $jq(".block-user h3 span").html(username);
        $jq(".block-user .x").on("click", function () {
            ClosePopups();
            if (typeof (params.onComplete) === "function") {
                params.onComplete({ blocked: false });
            }
        });
        $jq(".block-user > div > a:first-child").on("click", function () {
            ClosePopups();
            if (typeof (params.onComplete) === "function") {
                params.onComplete({ blocked: false });
            }
        });
        $jq(".block-user .button").on("click", function () {
            ConfirmBlock(params);
        });
        $jq(".block-user-confirm h3 .span").html(username);
        $jq("#PopupDisplayAreaDiv").show();
    };

    var ConfirmBlock = function (params) {
        var encodedMemberId = params.encodedMemberId;
        var username = params.username;
        var isTodaysMatches = params.isTodaysMatches;
        var publicMemberId = params.publicMemberId;
        $jq(".block-user .button").off();
        $jq(".block-user-confirm .x").on("click", function () {
            ClosePopups();
            if (typeof (params.onComplete) === "function") {
                params.onComplete({ blocked: true });
            }
        });
        $jq(".pop-up").hide();
        $jq(".pop-up.confirm").show();
        var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
        var startTime = new Date().getTime();
        var url = "/v3/Ajax/BlockUser";
        var timeoutValue = 60000;
        var ajaxData = {
            "EncodedMemberId": encodedMemberId,
            "BlockUser": true,
            "QueryString": window.location.search,
            "Username": username,
            "PageDefinitionId": pageDefinitionId,
            "PageDefinitionSubId": null,
            "PageNumber": null,
            "PageDefinitionView": null,
            "PublicMemberId": publicMemberId
        };
        $jq.ajax({
            headers: PeopleMedia.sitePostbackHeader(),
            type: "POST",
            url: url,
            data: JSON.stringify(ajaxData),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function (response) {
                // record blocked member to match stack if present
                RecordBlockedMemberToMatchStack(encodedMemberId, publicMemberId);

                if (ValidateResponse(response)) {
                    var todaysMatches = isTodaysMatches === "True";
                    if (!todaysMatches) {
                        if (response.backTo) {
                            if (response.backTo === ":back") {
                                setTimeout(function () {
                                    history.back();
                                }, 5000);
                            } else {
                                setTimeout(function () {
                                    location.href = response.backTo;
                                }, 5000);
                            }
                        }
                    } else {
                        setTimeout(function () {
                            location.reload();
                        }, 2000);
                    }
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (errorThrown === "timeout" || (jqXHR.readyState !== 0 && jqXHR.status !== 0)) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    // ShowGenericMessage(AjaxErrorMessage());
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "ConfirmBlock", duration: duration }, jqXHR, textStatus, errorThrown, function () {
                    });
                }
            },
            async: true,
            cache: false,
            timeout: timeoutValue,
            suppressErrors: true
        });
    };

    // needs to be provided either encoded id or public id to work
    var RecordBlockedMemberToMatchStack = function (blockedEncodedMemberId, blockedPublicMemberId) {
        try {
            // find blocked member in match stack session storage
            var sessionData = PeopleMedia.Storage.loadFromStorage(PeopleMedia.getMatchStackSessionStorageKey());
            if (sessionData && sessionData.displayMembers && sessionData.displayMembers.length) {
                // first try encodedMemberId
                var position = sessionData.displayMembers.map(function (e) { return e.ProfileId; })
                    .indexOf(blockedEncodedMemberId);

                // then try publicMemberId
                if (position === -1) {
                    position = sessionData.displayMembers.map(function (e) { return e.PublicMemberId; })
                        .indexOf(blockedPublicMemberId);
                }

                if (position > -1) {
                    var now = moment();
                    var expiresAt = now.add("minutes", 30);
                    var latestStackInfo = PeopleMedia.findLatestExpiresAt(sessionData.stackInfo);
                    var stackExpiresAt = (latestStackInfo && latestStackInfo.expiresAt) ? moment(latestStackInfo.expiresAt) : now.add("minutes", 30);
                    if (expiresAt.isAfter(stackExpiresAt)) {
                        expiresAt = stackExpiresAt;
                    }

                    sessionData.displayMembers[position].Blocked = true;
                    if (!blockedEncodedMemberId) {
                        blockedEncodedMemberId = sessionData.displayMembers[position].ProfileId;
                    }
                    sessionData.blockedProfiles[blockedEncodedMemberId] = {
                        blockedAt: now.format(),
                        expiresAt: expiresAt.format()
                    };

                    // save data back to session
                    PeopleMedia.Storage.saveToStorage(PeopleMedia.getMatchStackSessionStorageKey(), sessionData);

                    // if matchStack.js available, remove the blocked members
                    if (typeof (PeopleMedia.MatchStack) !== "undefined" &&
                        PeopleMedia.MatchStack !== null &&
                        typeof (PeopleMedia.MatchStack.removeBlockedMembers) === "function") {
                        PeopleMedia.MatchStack.removeBlockedMembers({
                            forceRefreshSession: true
                        });
                    }
                }
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "RecordBlockedMemberToMatchStack", blockedEncodedMemberId: blockedEncodedMemberId, blockedPublicMemberId: blockedPublicMemberId });
        }
    };

    var Init = function () {
    };
   
    var FlirtSent = function (encodedMemberId, username, gender, hasPhoto, fromPage, featureName, upn, isBdayFlirt, isDelighful) {
        var quickTip = "";
        var quickTipNumber = 0;
        var genderObjective = (gender === 1) ? "Him" : "Her";
        var flirtOrLikeText = (PeopleMedia.CombineFlirtsAndLikes) ? "like" : "flirt";
        var defaultTitle = (PeopleMedia.CombineFlirtsAndLikes) ? "Like Sent!" : "Flirt Sent!";
        var defaultText = "Hello, you caught my attention with...";

        var tipsMaleProfile = [
            "Quick Tip: Just say \"hi\" and introduce yourself. Yes. It really is that simple to stand out.",
            "Did you know the guys on the site prefer messages to "+flirtOrLikeText+"s? Yes! And they are 5x times more likely to respond to your message than your "+flirtOrLikeText+".",
            "Quick Tip: Stand out from the "+flirtOrLikeText+"s and tell him one thing that you liked about his profile.",
            "Quick Tip: Make him feel like a million bucks by mentioning what you like about his profile.",
            "Quick Tip: Increase your odds of getting a reply - ask about his interests."
        ];
        var tipsFemaleProfile = [
            "Quick Tip: Just say \"hi\" and introduce yourself. Yes. It really is that simple to stand out.",
            "Did you know...<br>Women greatly prefer getting a personalized message over a "+flirtOrLikeText+". So send a quick note to increase your chance of a reply by 5X!",
            "Quick Tip: Tell her one thing that you liked about her profile.",
            "Quick Tip: Increase your odds of getting a reply - ask about her interests.",
            "Quick Tip: Don't be shy, say Hi and let her know you're interested."
        ];

        var buttonCTA = "Tell " + genderObjective;

        if (isBdayFlirt != null && isBdayFlirt == true) {
            defaultTitle = "Birthday Wish Sent";
            defaultText = "Happy Birthday! I hope you...";
            quickTip = "Personalize Your Birthday Wish with a Message!";
            buttonCTA = "Send Message";
        } else if (hasPhoto) {
            quickTip = "Quick Tip: Compliment " + genderObjective.toLowerCase() + " on your favorite photo. What stands out to you?";
        } else {
            quickTipNumber = Math.floor(Math.random() * tipsMaleProfile.length);
            quickTip = (gender === 1) ? tipsMaleProfile[quickTipNumber] : tipsFemaleProfile[quickTipNumber];
        }
        var html =
            "<div class=\"pop-up\">" +
            "<div class=\"profile-flirt-sent\">" +
            "<i class=\"img x\" title=\"Close\"></i>" +
            "<h3>" + defaultTitle + "</h3>" +
            "<div class=\"tip\">" + quickTip + "</div>" +
            "<div><textarea id=\"PopupSendMessageTextArea\" name=\"PopupSendMessageTextArea\">" + defaultText + "</textarea></div>" +
            "<div><a href=\"javascript:void(0);\">Cancel</a><a href=\"javascript:void(0);\" id=\"PopupSendMessageConfirmButton\" class=\"button\" title=\"Tell " + genderObjective + "\">" + buttonCTA + "</a></div>" +
            "</div>" +
            "</div>";
        if (isDelighful != null && isDelighful == true) {
            html =
                "<div class=\"pop-up\">" +
                "<div class=\"profile-flirt-sent delightful\">" +
                "<i class=\"img x\" title=\"Close\"></i>" +
                "<h3>" + defaultTitle + "</h3>" +
                "<div class=\"tip\"><span>Compliment " + genderObjective.toLowerCase() + " on your<br>favorite photo. What<br>stands out to you?</span></div>" +
                "<div><textarea id=\"PopupSendMessageTextArea\" name=\"PopupSendMessageTextArea\">" + defaultText + "</textarea></div>" +
                "<div><a href=\"javascript:void(0);\"><i class=\"img no\" title=\"Cancel\"></i> cancel</a><a href=\"javascript:void(0);\" id=\"PopupSendMessageConfirmButton\" class=\"button\" title=\"Tell " + genderObjective + "\">" + buttonCTA + "</a></div>" +
                "</div>" +
                "</div>";
        }
        $jq("#PopupDisplayAreaDiv").empty().html(html);
        $jq("#PopupSendMessageConfirmButton").on("click", function () {
            ConfirmSendMessage(encodedMemberId, username, gender, hasPhoto, fromPage, featureName, upn, isBdayFlirt);
        });
        $jq(".profile-flirt-sent .x").on("click", ClosePopups);
        $jq(".profile-flirt-sent > div:last-child > a:first-child").on("click", ClosePopups);
        $jq("#PopupSendMessageTextArea").on("focus", function () {
            if ($jq(this).val() === defaultText) {
                var defaultText2 = defaultText.substring(0, defaultText.length - 3) + " ";
                setCursorPosition("PopupSendMessageTextArea", defaultText2.length);
                $jq(this).val(defaultText2);
                // Work around Chrome problem not allowing cursor position change from focus event
                window.setTimeout(function () {
                    setCursorPosition("PopupSendMessageTextArea", defaultText2.length);
                }, 500);
            }
        });
        $jq("#PopupSendMessageTextArea").on("keyup", function () {
            var limit = 3000;
            var text = $jq(this).val();
            var chars = text.length;
            if (chars > limit) {
                var new_text = text.substr(0, limit);
                $jq(this).val(new_text);
            }
        });
        $jq("#PopupDisplayAreaDiv").show();
    };

    var ConfirmSendMessage = function (encodedMemberId, username, gender, hasPhoto, fromPage, featureName, upn, isBdayFlirt) {
        $jq("#PopupSendMessageConfirmButton").off();
        $jq("#PopupSendMessageCancelButton").on("click", ClosePopups);
        $jq(".popupshowflirtsent").hide();
        var message = $jq("#PopupSendMessageTextArea").val().replace(/^\s+|\s+$/g, "");
        if (message && message !== "" && message !== "Hello, you caught my attention with..." && message !== "Hello, you caught my attention with" && message !== "Happy Birthday! I hope you..." && message !== "Happy Birthday! I hope you") {

            var todaysMatchCurrent = typeof (PeopleMedia.Profile) !== "undefined" && PeopleMedia.Profile.IsTodaysMatch ? PeopleMedia.Profile.TodaysMatchCurrent : 0;

            PeopleMedia.Messaging.sendMessage({
                encodedMemberId: encodedMemberId,
                featureName: featureName,
                message: message,
                upn: upn,
                fromPage: fromPage,
                todaysMatchCurrent: todaysMatchCurrent,
                suppressErrors: true,
                onComplete: function (err, response, data) {
                    if (response && response.status !== "Success") {
                        if (response.redirect) {
                            // upgrade
                            location.href = response.redirect;
                            return;
                        }
                        if (response.message) {
                            ShowGenericMessage(response.message, function () {
                                FlirtSent(encodedMemberId, username, gender, hasPhoto, fromPage, featureName, upn, isBdayFlirt);
                            });
                        }
                        return;
                    } else if (response && response.status === "Success") {
                        if (response.messageSentUrl) {
                            location.href = response.messageSentUrl;
                            return;
                        }
                    }
                }
            });
        } else {
            ShowGenericMessage("Please enter a message!", function () {
                FlirtSent(encodedMemberId, username, gender, hasPhoto, fromPage, featureName, upn, isBdayFlirt);
            });
            return;
        }
    };

    var ShowPrivacyPolicyUpdate = function (options) {
        if (typeof (options) === "undefined" || options === null) {
            return;
        }
        var html =
            "<div class=\"pop-up\">" +
                "<div class=\"privacy-policy-update\">" +
                "<i class=\"img x\"  title=\"Close\"></i>" +
                "<h3>Privacy Policy Update</h3>" +
                "<div class=\"policy-text\">On " + options.formattedEffectiveDate + ", " + ((options.isBeforeEffectiveDate === true) ? "we'll update" : "we updated") +
                " our Privacy Policy to make them clearer and to address new privacy laws. Learn more <a class=\"privacy-policy-link\" href=\"javascript:;\">here</a>." +
                "</div>" +
                "<div class=\"privacypolicyupdatebtn\"><a href=\"javascript:void(0);\" class=\"button privacy-policy-confirm\" title=\"GOT IT!\">GOT IT!</a></div>" +
                "</div>" +
                "</div>";
        $jq("#PopupDisplayAreaDiv").empty().html(html);
        $jq("#PopupDisplayAreaDiv").show();

        // treat closing the popup the same as clicking on the button
        $jq(".privacy-policy-update .x").on("click", options, ShowPrivacyPolicyUpdateComplete);
        $jq(".privacy-policy-update .privacy-policy-confirm").on("click", options, ShowPrivacyPolicyUpdateComplete);
        $jq(".privacy-policy-update a.privacy-policy-link").on("click", options, TrackPrivacyPolicyAndRedirect);
    };

    var ShowPrivacyPolicyUpdateComplete = function(event) {
        TrackUserAgreement(event, function () {
            // do nothing
        });
    };

    var TrackUserAgreement = function (event, onComplete) {
        if (!event || !event.data) {
            if (typeof (onComplete) === "function") {
                return onComplete();
            }
            return false;
        }
        var options = event.data;
        event.preventDefault();
        event.stopPropagation();
        var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
        var startTime = new Date().getTime();
        var url = "/v3/Ajax/TrackUserAgreement";
        var timeoutValue = 60000;
        var ajaxData = {
            "UserAgreementVersionId": options.userAgreementVersionId,
            "PendingUserAgreementVersionId": options.pendingUserAgreementVersionId,
            "UserAgreementName": options.userAgreementName,
            "PageDefinitionId": pageDefinitionId,
            "PageDefinitionSubId": null,
            "PageNumber": null,
            "PageDefinitionView": null
        };
        $jq.ajax({
            headers: PeopleMedia.sitePostbackHeader(),
            type: "POST",
            url: url,
            data: JSON.stringify(ajaxData),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function (response) {
                if (response && response.redirect) {
                    location.href = response.redirect;
                }
                ClosePopups();
                if (typeof (onComplete) === "function") {
                    return onComplete();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                ClosePopups();
                if (errorThrown === "timeout" || (jqXHR.readyState !== 0 && jqXHR.status !== 0)) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    // ShowGenericMessage(AjaxErrorMessage());
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "TrackUserAgreement", duration: duration }, jqXHR, textStatus, errorThrown);
                }
                if (typeof (onComplete) === "function") {
                    return onComplete();
                }
            },
            async: true,
            cache: false,
            timeout: timeoutValue,
            suppressErrors: true
        });
    };

    var TrackPrivacyPolicyAndRedirect = function (event) {
        // redirect after tracking is completed
        return TrackUserAgreement(event, function () {
            location.href = "/v3/features/privacypolicy";
        });
    };

    var setCursorPosition = function (inputId, pos) {
        var input = document.getElementById(inputId);
        if (input && input.value) {
            setSelectionRange(input, pos, pos);
        }
    };

    var setSelectionRange = function (input, selectionStart, selectionEnd) {
        if (input.setSelectionRange) {
            input.focus();
            input.setSelectionRange(selectionStart, selectionEnd);
        } else if (input.createTextRange) {
            var range = input.createTextRange();
            range.collapse(true);
            range.moveEnd("character", selectionEnd);
            range.moveStart("character", selectionStart);
            range.select();
        }
    };

    var ClosePopups = function () {
        // Specify zero on hide duration so we avoid timing issues with callback and future manipulation to element being hidden
        $jq("#PopupDisplayAreaDiv").hide(0, _closeCallBack);
    };

    return {
        setOnCloseCallback: setOnCloseCallback,
        AjaxErrorMessage: AjaxErrorMessage,
        ShowGenericMessage: ShowGenericMessage,
        LongMessage: LongMessage,
        LearnMore: LearnMore,
        ShareProfile: ShareProfile,
        BlockUser: BlockUser,
        RecordBlockedMemberToMatchStack: RecordBlockedMemberToMatchStack,
        Init: Init,
        ReportConcern: PeopleMedia.ReportConcern.init,
        FlirtSent: FlirtSent,
        ShowPrivacyPolicyUpdate: ShowPrivacyPolicyUpdate,
        ClosePopups: ClosePopups,
        CheckProfileBouncer: CheckProfileBouncer
    };
})(jQuery, moment);

PeopleMedia.ChatPane = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.ChatPane";

    var chatPane = null;
    var columnLeft = null;
    var pub = [];
    var wired = false;
    var win = null;
    pub.IsEnabled = false;
    var isShowing = false;
    var lastToggle = null;
    var shouldToggle = function () {
        if (lastToggle == null) {
            lastToggle = new Date();
            return true;
        } else {
            var newToggle = new Date();
            if ((newToggle - lastToggle) > 600) {
                lastToggle = newToggle;
                return true;
            }
        }
        return false;
    };
    pub.ShowChat = function (forceEnabled) {
        if (forceEnabled == true || forceEnabled == false) {
            pub.IsEnabled = forceEnabled;
        }
        if (wired == false) {
            win = $jq(window);
            chatPane = $jq("div.chatPane");
            columnLeft = $jq("div#columnleft");

            wired = true;
        }
        if (chatPane == null || chatPane.length == 0 || pub.IsEnabled == false || !shouldToggle()) {
            return;
        }
        isShowing = true;
        win.off("scroll.chatPane").on("scroll.chatPane", scrolled);
        win.off("resize.chatPane").on("resize.chatPane", resized);
        chatPane.animate({ "right": "0px" }, 400);
        resized();
        var startTime = new Date().getTime();
        var timeoutValue = 60000;
        var url = "/v3/chat/getavailablemembers";
        $jq.ajax({
            url: url,
            type: "GET",
            data: {},
            success: populateMembers,
            error: function (jqXHR, textStatus, errorThrown) {
                var endTime = new Date().getTime();
                var duration = (endTime - startTime);
                // PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "pub.ShowChat", duration: duration }, jqXHR, textStatus, errorThrown, function () {
                });

                var h1 = chatPane.find(">h1").clone();
                h1.off("click").on("click", pub.HideChat);
                chatPane.children().remove();
                chatPane.append(h1);
                var h2 = $jq("<h2>Sorry, we are unable to load chat list at this time...</h2>");
                chatPane.append(h2);
            },
            async: true,
            cache: false,
            timeout: timeoutValue,
            suppressErrors: true
        });

        startTime = new Date().getTime();
        timeoutValue = 60000;
        url = "/v3/chat/UpdateShowHideChatPanel";
        var ajaxData = {
             "show": true
        };
        $jq.ajax({
            url: url,
            type: "POST",
            data: JSON.stringify(ajaxData),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function (response) {
            },
            error: function (jqXHR, textStatus, errorThrown) {
            },
            async: true,
            cache: false,
            timeout: timeoutValue,
            suppressErrors: true
        });
    };
    pub.ToggleChat = function () {
        if (isShowing == true) {
            pub.HideChat();
        } else {
            pub.ShowChat();
        }
    };
    var populateMembers = function (result) {
        var h1 = chatPane.find(">h1").clone();
        h1.off("click").on("click", pub.HideChat);
        chatPane.children().remove();
        chatPane.append(h1);
        if (result.success == true) {
            if (result.Members && result.Members.length > 0) {
                $jq.each(result.Members, function (fx) {
                    var member = result.Members[fx];
                    var div = $jq("<div></div>");
                    var img = $jq("<img>");
                    img.prop("src", member.PhotoUrl);
                    div.append(img);
                    div.prop("title", "Click to Chat with " + member.Username + " Now!");
                    div.append("<h3>" + member.Username + "</h3>");
                    if (member.IsFavorite == true) {
                        div.addClass("fav");
                        if (member.Gender == 1) {
                            div.append("<h4>He's a Favorite!</h4>");
                        } else {
                            div.append("<h4>She's a Favorite!");
                        }
                    } else {
                        div.append("<h4>" + member.Age + " | " + member.Location + "</h4>");
                    }
                    if (result.IsPaid || result.upgradeOnSend) {
                        member.UpgradeUrl = (!result.IsPaid) ? result.UpgradeUrl + "&profile=" + member.EncryptedMemberId : null;
                        div.on("click", function () { initiateChat(member); });
                    } else {
                        div.on("click", function () {
                            location.href = result.UpgradeUrl + "&profile=" + member.EncryptedMemberId;
                        });
                    }
                    chatPane.append(div);
                });
                chatPane.append($jq("<h5></h5>").addClass("last"));
            } else {
                var h2 = $jq("<h2>No members online at this time</h2>");
                chatPane.append(h2);
            }
        } else {
            var h2 = $jq("<h2>Sorry, we are unable to load chat list at this time...</h2>");
            chatPane.append(h2);
        }
    };
    var initiateChat = function (member) {
        if (PMChat.ChatEnabled == false) {
            alert("You must enable chat to send and receive messages!");
            return;
        }
        PMChat.Client({
            SiteID: member.SiteId,
            MemberID: member.EncryptedMemberId,
            NickName: member.Username,
            PhotoUrl: member.PhotoUrl,
            UpgradeUrl: member.UpgradeUrl,
            Age: member.Age,
            Location: member.Location,
            City: member.City,
            StateCode: member.StateCode,
            CountryCode: member.CountryCode,
            Gender: member.Gender
        });
        PMChat.UI.ChatTabFocus({ WithMember: { MemberID: member.EncryptedMemberId } }, true);
    };

    pub.HideChat = function () {
        if (chatPane == null || chatPane.length == 0 || pub.IsEnabled == false || !shouldToggle()) {
            return;
        }
        isShowing = false;
        win.off("scroll.chatPane");
        win.off("resize.chatPane");

        columnLeft.animate({ "margin-left": "0px" }, 400);
        chatPane.animate({ "right": "-200px" }, 400, function () {
            var h1 = chatPane.find(">h1").clone();
            chatPane.children().remove();
            chatPane.append(h1);
            chatPane.append("<p>Loading Members...</p>");
        });
        var startTime = new Date().getTime();
        var timeoutValue = 60000;
        var url = "/v3/chat/UpdateShowHideChatPanel";
        var ajaxData = {
            "show": false
        };
        $jq.ajax({
            url: url,
            type: "POST",
            data: JSON.stringify(ajaxData),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function (response) {
            },
            error: function (jqXHR, textStatus, errorThrown) {
            },
            async: true,
            cache: false,
            timeout: timeoutValue,
            suppressErrors: true
        });
    };
    var resized = function () {
        var win = $jq(window);
        var leftColMargin = columnLeft.css("margin-left");
        var width = win.width();
        if (width > 1330) {
            if (leftColMargin != "-95px") {
                columnLeft.animate({ "margin-left": "-95px" }, 400);
            }
        } else if (width > 1280) {
            if (leftColMargin != "-55px") {
                columnLeft.animate({ "margin-left": "-55px" }, 400);
            }
        } else {
            if (leftColMargin != "-45px") {
                columnLeft.animate({ "margin-left": "-45px" }, 400);
            }
        }

        scrolled();
    };
    var scrolled = function () {
        var win = $jq(window);
        var scrollTop = win.scrollTop();

        if (scrollTop > 80) {
            if (chatPane.css("top") == "0") return;
            chatPane.css("top", "0px");
        } else if (scrollTop >= 0) {
            chatPane.css("top", 80 - scrollTop + "px");
        }
    };

    return pub;
})(jQuery);

PeopleMedia.WebSyncServer = (function () {
    "use strict";

    var Oid = "PeopleMedia.WebSyncServer";

    var _client = null;

    var Init = function () {
        if (_client == null &&
            !PeopleMediaConfig.Configuration.isUpgradePage &&
            typeof (fm) !== "undefined") {
            if (typeof (fm.websync.client) == "object") {
                // WSv3
                _client = fm.websync.client;
            } else {
                //WSv4+
                _client = new fm.websync.client(PeopleMediaConfig.Configuration.webSyncUrl);
            }

            if (_client.initialize)
                _client.initialize();

            _client.connect();
        }
    };

    return {
        get Client() { return _client; },
        get WebSyncUrl() { return PeopleMediaConfig.Configuration.webSyncUrl; },
        get Enabled() { return !PeopleMediaConfig.Configuration.isUpgradePage; },
        Init: Init
    };
})();

PeopleMedia.LikeMedia = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.LikeMedia";

    var likeMedia = function (params) {
        params = params || {};
        var encodedMemberId = params.encodedMemberId || null;
        var publicMemberId = params.publicMemberId || null;
        var featureName = params.featureName || null;
        var mediaId = params.mediaId || null;
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";
        var suppressErrors = params.suppressErrors === true;

        try {
            var apiSendEnabled = !!PeopleMedia.ApiSendEnabled;
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
            var startTime = new Date().getTime();
            var timeoutValue = 60000;
            var url = (apiSendEnabled) ? "/v3/Ajax/SendApiPhotoLike" : "/v3/Ajax/LikePhoto";
            var ajaxData = {
                "EncodedMemberId": encodedMemberId,
                "PublicMemberId": publicMemberId,
                "ObjectId": mediaId,
                "FeatureName": featureName,
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null
            };
            if (params.pageNumber) {
                ajaxData.PageNumber = params.pageNumber;
            }
            if (params.pageDefinitionView) {
                ajaxData.PageDefinitionView = params.pageDefinitionView;
            }
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    if (response && response.redirect) {
                        location.href = response.redirect;
                        return;
                    }
                    if (response && response.status === "Success") {
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    var errorMessage = (response && response.message) ? response.message : null;
                    var isDuplicate = (response && response.isDuplicate);
                    if (!suppressErrors && !isDuplicate) {
                        PeopleMedia.Popups.ShowGenericMessage(errorMessage);
                    }
                    if (hasOnComplete) {
                        var errProp = {};
                        if (response && response.isDuplicate) {
                            errProp.isDuplicate = true;
                        }
                        onComplete(PeopleMedia.createErrorObject(errProp), response, params);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                    }
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "likeMedia", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "likeMedia" });
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var commentMedia = function (params) {
        params = params || {};
        var encodedMemberId = params.encodedMemberId || null;
        var publicMemberId = params.publicMemberId || null;
        var featureName = params.featureName || null;
        var mediaId = params.mediaId || null;
        var message = params.message || null;
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";
        var suppressErrors = params.suppressErrors === true;

        try {
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
            var startTime = new Date().getTime();
            var timeoutValue = 60000;
            var url = "/v3/Ajax/CommentPhoto";
            var ajaxData = {
                "EncodedMemberId": encodedMemberId,
                "PublicMemberId": publicMemberId,
                "ObjectId": mediaId,
                "Message": message,
                "FeatureName": featureName,
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null,
                "SkipCSSVerif": "HTMLEditor"
            };
            if (params.pageNumber) {
                ajaxData.PageNumber = params.pageNumber;
            }
            if (params.pageDefinitionView) {
                ajaxData.PageDefinitionView = params.pageDefinitionView;
            }
            if (params.upn) {
                ajaxData.UPN = params.upn;
            }
            if (params.fromPage) {
                ajaxData.FromPage = params.fromPage;
            }
            if (params.includeQueryString === true) {
                url += window.location.search;
            }
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    if (response && response.redirect) {
                        location.href = response.redirect;
                        return;
                    }
                    if (response && response.status === "Success") {
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    var errorMessage = (response && response.message) ? response.message : null;
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(errorMessage);
                    }
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createErrorObject({}), response, params);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                    }
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "commentMedia", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "commentMedia" });
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    return {
        likeMedia: likeMedia,
        commentMedia: commentMedia
    };
})(jQuery);

PeopleMedia.Messaging = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Messaging";
    var _messagePromptInProgress = false;
    var _messagePrompts = [];
    var _messagePromptsLegacy = [];
    var _messagePromptsAll = [];
    var _messagePromptsLegacyAll = [];
    var isSendingMessage = false;
    var _months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var _days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    var sendMessage = function (params) {
        params = params || {};
        var encodedMemberId = params.encodedMemberId || null;
        var publicMemberId = params.publicMemberId || null;
        var featureName = params.featureName || null;
        var fromPage = params.fromPage || null;
        var pageDefinitionId = params.pageDefinitionId || null;
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";
        var suppressErrors = params.suppressErrors === true;
        try {
            if (isSendingMessage === true) return;
            isSendingMessage = true;

            if (!pageDefinitionId && typeof (PeopleMedia.PageDefinitionId) !== "undefined") {
                pageDefinitionId = PeopleMedia.PageDefinitionId;
            }
            var apiSendEnabled = !!PeopleMedia.ApiSendEnabled;	
            var url = (apiSendEnabled) ? "/v3/Ajax/SendApiMessage" : "/v3/Ajax/SendMessage";
            var startTime = new Date().getTime();
            var timeoutValue = 60000;
            var ajaxData = {
                "EncodedMemberId": encodedMemberId,
                "PublicMemberId": publicMemberId,
                "FeatureName": featureName,
                "FromPage": fromPage,
                "SkipCSSVerif": "HTMLEditor",
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null
            };
            if (params.pageNumber) {
                ajaxData.PageNumber = params.pageNumber;
            }
            if (params.pageDefinitionView) {
                ajaxData.PageDefinitionView = params.pageDefinitionView;
            }
            if (params.actionType) {
                ajaxData.ActionType = params.actionType;
            }
            if (params.objectId) {
                ajaxData.ObjectId = params.objectId;
            }
            if (params.todaysMatchCurrent) {
                ajaxData.TodaysMatchCurrent = params.todaysMatchCurrent;
            }
            if (params.searchId) {
                ajaxData.SearchId = params.searchId;
            }
            if (params.subject) {
                ajaxData.Subject = params.subject;
            }
            if (params.message) {
                ajaxData.Message = params.message;
            }
            if (params.upn) {
                ajaxData.UPN = params.upn;
            }
            if (params.fromPage) {
                ajaxData.FromPage = params.fromPage;
            }
            if (params.isCannedMessage) {
                ajaxData.IsCannedMessage = params.isCannedMessage;
            }
            if (params.prepopulatedMessageId) {
                ajaxData.PrepopulatedMessageId = params.prepopulatedMessageId;
            }
            if (params.trackingModule) {
                ajaxData.TrackingModule = params.trackingModule;
            }
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    isSendingMessage = false;
                    if (response && response.status !== "Success" && response.redirect) {
                        // upgrade
                        location.href = response.redirect;
                        return;
                    }
                    if (response && response.status === "Success") {
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    var errorMessage = (response && response.message) ? response.message : null;
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(errorMessage);
                    }
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createErrorObject({}), response, params);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    isSendingMessage = false;
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                    }
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "sendFlirt", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            isSendingMessage = false;
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "sendMessage" });
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var getMessagePromptsForSite = function (params) {
        params = params || {};
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";
        try {
            // skip if already loaded
            if (!params.forceRefresh && _messagePrompts && _messagePrompts.length > 0) {
                if (hasOnComplete) {
                    onComplete(null, { status: "Success", data: { messagePrompts: _messagePrompts }}, params);
                }
                return;
            }

            // handle case when call is already in progress
            if (_messagePromptInProgress) {
                // wait .5s and retry once
                setTimeout(function() {
                    if (params.isRetry) {
                        if (hasOnComplete) {
                            onComplete(PeopleMedia.createErrorObject({}), null, params);
                        }
                        return;
                    } else {
                        params.isRetry = true;
                        getMessagePromptsForSite(params);
                    }
                }, 500);
                return;
            }

            _messagePromptInProgress = true;

            var url = "/v3/Ajax/GetMessagePromptsForSite";
            var startTime = new Date().getTime();
            var timeoutValue = 10000;
            var ajaxData = {};
            $jq.ajax({
                type: "GET",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    _messagePromptInProgress = false;
                    if (response && response.status === "Success" && response.data && response.data.messagePrompts) {
                        _messagePrompts.length = 0;
                        _messagePromptsLegacy.length = 0;
                        _messagePromptsAll.length = 0;
                        _messagePromptsLegacyAll.length = 0;
                        Array.prototype.push.apply(_messagePromptsAll, response.data.messagePrompts
                            .filter(function (obj) {
                                return obj && obj.text;
                            }));
                        Array.prototype.push.apply(_messagePromptsLegacyAll, response.data.messagePrompts
                            .filter(function (obj) {
                                return obj && obj.text && obj.isLegacy === true;
                            }));
                        Array.prototype.push.apply(_messagePrompts, _messagePromptsAll);
                        Array.prototype.push.apply(_messagePromptsLegacy, _messagePromptsLegacyAll);
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    _messagePrompts.length = 0;
                    _messagePromptsLegacy.length = 0;
                    _messagePromptsAll.length = 0;
                    _messagePromptsLegacyAll.length = 0;
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createErrorObject({}), response, params);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    _messagePromptInProgress = false;
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "getMessagePromptsForSite", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    _messagePrompts.length = 0;
                    _messagePromptsLegacy.length = 0;
                    _messagePromptsAll.length = 0;
                    _messagePromptsLegacyAll.length = 0;
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            _messagePromptInProgress = false;
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "getMessagePromptsForSite" });
            _messagePrompts.length = 0;
            _messagePromptsLegacy.length = 0;
            _messagePromptsAll.length = 0;
            _messagePromptsLegacyAll.length = 0;
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var getRandomMessagePrompt = function (params) {
        try {
            var useLegacy = (params && params.isLegacy === true);
            var removeAfterUse = (params && params.removeAfterUse === true);
            var values = useLegacy ? _messagePromptsLegacy : _messagePrompts;
            var randomNumber = Math.floor(Math.random() * values.length);
            var response = values[randomNumber];
            if (removeAfterUse) {
                // remove message prompt which has been displayed
                values.splice(randomNumber, 1);

                // repopulate with all message prompts once they have all been displayed
                if (values.length === 0) {
                    if (useLegacy) {
                        Array.prototype.push.apply(_messagePromptsLegacy, _messagePromptsLegacyAll);
                    } else {
                        Array.prototype.push.apply(_messagePrompts, _messagePromptsAll);
                    }
                }
            }
            return response;
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { oid: Oid, method: "getRandomMessagePrompt" });
        }
    };

    // returns time in format HH:mm AM/PM
    var getFormattedTimeFromTimestamp = function (timestamp) {
        var formattedTime = "";

        if (timestamp && typeof (timestamp) === "number") {
            var msgDate = new Date(timestamp);

            var hr = msgDate.getHours();
            var min = msgDate.getMinutes();
            if (min < 10) {
                min = "0" + min;
            }
            var ampm = "AM";
            if (hr === 12) {
                ampm = "PM";
            } else if (hr > 12) {
                hr -= 12;
                ampm = "PM";
            } else if (hr === 0) {
                hr = 12;
            }
            formattedTime = hr + ":" + min + " " + ampm;
        }

        return formattedTime;
    };

    var formatMessageTimestamp = function (params) {
        var timestamp = params.timestamp;
        var mrc = params.mrc === true;
        var formattedDate = "";
        var today = new Date();
        var yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        var lastWeek = new Date(today);
        lastWeek.setDate(today.getDate() - 6);
        lastWeek.setHours(0, 0, 0, 0);
        var lastYear = new Date(today);
        lastYear.setFullYear(today.getFullYear() - 1);
        lastYear.setHours(23, 59, 59, 999);

        if (timestamp && typeof (timestamp) === "number") {
            var msgDate = new Date(timestamp);

            // Sent today => Time is displayed (ex. 10:00 AM)
            if (msgDate.getFullYear() === today.getFullYear()
                && msgDate.getMonth() === today.getMonth()
                && msgDate.getDate() === today.getDate()) {
                formattedDate = getFormattedTimeFromTimestamp(timestamp);
            }
            // Sent previous calendar day => Yesterday + time is displayed
            else if (msgDate.getFullYear() === yesterday.getFullYear()
                && msgDate.getMonth() === yesterday.getMonth()
                && msgDate.getDate() === yesterday.getDate()) {
                formattedDate = "Yesterday";
                if (!mrc) {
                    formattedDate += ", " + getFormattedTimeFromTimestamp(timestamp);
                }
            }
            // Sent 2 to 7 calendar days ago => Day of the week + time is displayed (ex. Tuesday, 10:00 AM)
            else if (msgDate.getTime() >= lastWeek.getTime()) {
                formattedDate = _days[msgDate.getDay()];
                if (!mrc) {
                    formattedDate += ", " + getFormattedTimeFromTimestamp(timestamp);
                }
            }
            // Sent 7+ calendar days to 1 calendar year ago => Month abbreviation and day + time are displayed (ex. Aug 7, 10:00 AM)
            else if (msgDate.getTime() > lastYear.getTime()) {
                formattedDate = _months[msgDate.getMonth()] + " " + msgDate.getDate();
                if (!mrc) {
                    formattedDate += ", " + getFormattedTimeFromTimestamp(timestamp);
                }
            }
            // Sent more than 1 calendar year ago => MM/DD/YY + time is displayed (ex. 12/24/20, 10:00AM)
            else {
                var month = msgDate.getMonth();
                var day = msgDate.getDay();
                var year = msgDate.getFullYear().toString().slice(-2);
                formattedDate = month + "/" + day + "/" + year;
                if (!mrc) {
                    formattedDate += ", " + getFormattedTimeFromTimestamp(timestamp);
                }
            }
        }

        return formattedDate;
    };

    // get time formatted like "Minutes Ago", "Days Ago", etc.
    var formatRelativeTimeFromTimestamp = function(timestamp) {
        var text = "";
        if (timestamp && typeof (timestamp) === "number") {
            var timeElapsedMins = (Date.now() - timestamp) / 1000 / 60;
            var timeElapsedHours = timeElapsedMins > 0 ? timeElapsedMins / 60 : 0;
            var timeElapsedDays = timeElapsedHours > 0 ? timeElapsedHours / 24 : 0;
            var timeElapsedYears = timeElapsedDays > 0 ? timeElapsedDays / 365 : 0;
            if (timeElapsedMins < 60) {
                var displayMins = Math.floor(timeElapsedMins);
                if (displayMins === 0) {
                    displayMins = 1; // don't show '0 Minutes'
                }
                text = displayMins + " Minute" + (displayMins === 1 ? "" : "s") + " Ago";
            } else if (timeElapsedHours < 24) {
                var displayHours = Math.floor(timeElapsedHours);
                text = displayHours + " Hour" + (displayHours === 1 ? "" : "s") + " Ago";
            } else if (timeElapsedDays < 365) {
                var displayDays = Math.floor(timeElapsedDays);
                text = displayDays + " Day" + (displayDays === 1 ? "" : "s") + " Ago";
            } else if (timeElapsedYears > 0) {
                var displayYears = Math.floor(timeElapsedYears);
                text = displayYears + " Year" + (displayYears === 1 ? "" : "s") + " Ago";
            }
        }

        return text;
    };

    var getConnectionType = function (params) {
        params = params || {};
        var publicMemberId = params.publicMemberId || null;
        var pageDefinitionId = params.pageDefinitionId || null;
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";
        try {
            if (!pageDefinitionId && typeof (PeopleMedia.PageDefinitionId) !== "undefined") {
                pageDefinitionId = PeopleMedia.PageDefinitionId;
            }
            var url = "/v3/Ajax/GetConnectionType";
            var startTime = new Date().getTime();
            var timeoutValue = 5000;
            var ajaxData = {
                "PublicMemberId": publicMemberId,
                "PageDefinitionId": pageDefinitionId
            };
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    if (response && response.status !== "Success" && response.redirect) {
                        // upgrade
                        location.href = response.redirect;
                        return;
                    }
                    if (response && response.status === "Success") {
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createErrorObject({}), response, params);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "getConnectionType", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "getConnectionType" });
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var checkForNewMutualMatch = function (params) {
        params = params || {};
        var publicMemberId = params.publicMemberId || null;
        var previousConnectionType = params.connectionType || null;
        var previousThreadType = params.threadType || null;
        var refresh = params.refresh === true;
        var pageDefinitionId = params.pageDefinitionId || null;
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";

        var showMatchMutuals = !!PeopleMedia.ShowMatchMutualConnections;
        var isMutualMessageThread = (previousConnectionType === PeopleMedia.Constants.ConnectionType.Mutual &&
            previousThreadType === PeopleMedia.Constants.ThreadType.Message);

        // skip calls if Match Mutuals disabled, other member is missing or this is already a mutual message thread
        if (!showMatchMutuals || !publicMemberId || !hasOnComplete || (isMutualMessageThread && !refresh)) {
            onComplete(null, {
                publicMemberId: publicMemberId,
                connectionType: previousConnectionType,
                threadType: previousThreadType,
                newMutualMatch: false
            }, params);
            return;
        }

        getConnectionType({
            publicMemberId: publicMemberId,
            pageDefinitionId: pageDefinitionId,
            onComplete: function (err, response) {
                if (!err && response && response.status === "Success") {
                    response.newMutualMatch = response.connectionType === PeopleMedia.Constants.ConnectionType.Mutual &&
                        response.connectionType !== previousConnectionType;
                }
                onComplete(err, response, params);
            }
        });
    };

    return {
        get MessagePrompts() { return _messagePrompts; },
        get MessagePromptsLegacy() { return _messagePromptsLegacy; },
        sendMessage: sendMessage,
        getMessagePromptsForSite: getMessagePromptsForSite,
        getRandomMessagePrompt: getRandomMessagePrompt,
        formatMessageTimestamp: formatMessageTimestamp,
        formatRelativeTimeFromTimestamp: formatRelativeTimeFromTimestamp,
        getConnectionType: getConnectionType,
        checkForNewMutualMatch: checkForNewMutualMatch
    };
})(jQuery);

PeopleMedia.Flirt = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Flirt";

    var sendFlirt = function(params) {
        params = params || {};
        var encodedMemberId = params.encodedMemberId || null;
        var publicMemberId = params.publicMemberId || null;
        var featureName = params.featureName || null;
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof(onComplete) === "function";
        var suppressErrors = params.suppressErrors === true;
        try {
            var apiSendEnabled = !!PeopleMedia.ApiSendEnabled;
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined"
                ? PeopleMedia.PageDefinitionId
                : null;
            var url = (apiSendEnabled) ? "/v3/Ajax/SendApiProfileLike" : "/v3/Ajax/SendFlirt";
            var startTime = new Date().getTime();
            var timeoutValue = 60000;
            var ajaxData = {
                "EncodedMemberId": encodedMemberId,
                "FeatureName": featureName,
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null,
                "PublicMemberId": publicMemberId
            };
            if (params.pageNumber) {
                ajaxData.PageNumber = params.pageNumber;
            }
            if (params.pageDefinitionView) {
                ajaxData.PageDefinitionView = params.pageDefinitionView;
            }
            if (params.replyToMessageId) {
                ajaxData.ReplyToMessageId = params.replyToMessageId;
            }
            if (params.actionType) {
                ajaxData.ActionType = params.actionType;
            }
            if (params.objectId) {
                ajaxData.ObjectId = params.objectId;
            }
            if (params.todaysMatchCurrent) {
                ajaxData.TodaysMatchCurrent = params.todaysMatchCurrent;
            }
            if (params.searchId) {
                ajaxData.SearchId = params.searchId;
            }
            if (params.flirtId) {
                ajaxData.FlirtId = params.flirtId;
            }
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function(response) {
                    if (response && response.redirect) {
                        location.href = response.redirect;
                        return;
                    }
                    if (response && response.status === "Success") {
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    var errorMessage = (response && response.message) ? response.message : null;
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(errorMessage);
                    }
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createErrorObject({}), response, params);
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                    }
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "sendFlirt", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "sendFlirt" });
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var sendBirthdayFlirt = function (params) {
        params = params || {};
        var encodedMemberId = params.encodedMemberId || null;
        var publicMemberId = params.publicMemberId || null;
        var featureName = params.featureName || null;
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";
        var suppressErrors = params.suppressErrors === true;
        try {
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined"
                ? PeopleMedia.PageDefinitionId
                : null;
            var url = "/v3/Ajax/SendBirthdayFlirt";
            var startTime = new Date().getTime();
            var timeoutValue = 60000;
            var ajaxData = {
                "EncodedMemberId": encodedMemberId,
                "FeatureName": featureName,
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null,
                "PublicMemberId": publicMemberId
            };
            if (params.pageNumber) {
                ajaxData.PageNumber = params.pageNumber;
            }
            if (params.pageDefinitionView) {
                ajaxData.PageDefinitionView = params.pageDefinitionView;
            }
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    if (response && response.redirect) {
                        location.href = response.redirect;
                        return;
                    }
                    if (response && response.status === "Success") {
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    var errorMessage = (response && response.message) ? response.message : null;
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(errorMessage);
                    }
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createErrorObject({}), response, params);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                    }
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "sendBirthdayFlirt", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "sendBirthdayFlirt" });
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var sendMissingPhotoFlirt = function (params) {
        try {
            var missingPhotoParams = $jq.extend({}, params, { flirtId: 7 });
            sendFlirt(missingPhotoParams);

        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "sendMissingPhotoFlirt" });
            if (params && typeof (params.onComplete) === "function") {
                params.onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var sendImInterestedFlirt = function (params) {
        try {
            var imInterestedParams = $jq.extend({}, params, { flirtId: 8 });
            sendFlirt(imInterestedParams);

        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "sendImInterestedFlirt" });
            if (typeof (params.onComplete) === "function") {
                params.onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    return {
        sendFlirt: sendFlirt,
        sendBirthdayFlirt: sendBirthdayFlirt,
        sendMissingPhotoFlirt: sendMissingPhotoFlirt,
        sendImInterestedFlirt: sendImInterestedFlirt
    };
})(jQuery);

PeopleMedia.Favorite = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Favorite";

    var favoriteAction = function (params) {
        params = params || {};
        var encodedMemberId = params.encodedMemberId || null;
        var featureName = params.featureName || null;
        var isAddFavorite = typeof (params.action) !== "undefined" && params.action === "add";
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";
        var suppressErrors = params.suppressErrors === true;

        try {
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
            var startTime = new Date().getTime();
            var timeoutValue = 30000;
            var url = (isAddFavorite) ? "/v3/Ajax/SaveToFavorites" : "/v3/Ajax/UnFavorite";
            var ajaxData = {
                "EncodedMemberId": encodedMemberId,
                "FeatureName": featureName,
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null
            };
            if (params.pageNumber) {
                ajaxData.PageNumber = params.pageNumber;
            }
            if (params.pageDefinitionView) {
                ajaxData.PageDefinitionView = params.pageDefinitionView;
            }
            if (params.searchId) {
                ajaxData.SearchId = params.searchId;
            }
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    if (response && response.redirect) {
                        location.href = response.redirect;
                        return;
                    }
                    if (response && response.status === "Success") {
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    var errorMessage = (response && response.message) ? response.message : null;
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(errorMessage);
                    }
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createErrorObject({}), response, params);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                    }
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "favoriteAction", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "favoriteAction" });
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var addFavorite = function (params) {
        var favParams = $jq.extend({}, params, { action: "add" });
        favoriteAction(favParams);
    };

    var removeFavorite = function (params) {
        var removeParams = $jq.extend({}, params, { action: "remove" });
        favoriteAction(removeParams);
    };

    return {
        addFavorite: addFavorite,
        removeFavorite: removeFavorite
    };
})(jQuery);

PeopleMedia.Media = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Media";

    var getProfilePhotos = function (params) {
        params = params || {};
        var encodedMemberId = params.encodedMemberId || null;
        var publicMemberId = params.publicMemberId || null;
        var featureName = params.featureName || null;
        var onComplete = params.onComplete || null;
        var hasOnComplete = typeof (onComplete) === "function";
        var suppressErrors = params.suppressErrors === true;

        try {
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
            var startTime = new Date().getTime();
            var timeoutValue = 30000;
            var url = "/v3/Ajax/ProfilePhotos";
            var ajaxData = {
                "EncodedMemberId": encodedMemberId,
                "FeatureName": featureName,
                "MediaId": null,
                "MaxResults": null,
                "ImageSize": null,
                "PageDefinitionId": pageDefinitionId,
                "PageDefinitionSubId": null,
                "PageNumber": null,
                "PageDefinitionView": null,
                "PublicMemberId": publicMemberId
            };
            if (params.mediaId) {
                ajaxData.MediaId = params.mediaId;
            }
            if (params.maxResults) {
                ajaxData.MaxResults = params.maxResults;
            }
            if (params.imageSize) {
                ajaxData.ImageSize = params.imageSize;
            }
            if (params.pageNumber) {
                ajaxData.PageNumber = params.pageNumber;
            }
            if (params.pageDefinitionView) {
                ajaxData.PageDefinitionView = params.pageDefinitionView;
            }
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    if (response && response.redirect) {
                        location.href = response.redirect;
                        return;
                    }
                    if (response && response.status === "Success") {
                        if (hasOnComplete) {
                            onComplete(null, response, params);
                        }
                        return;
                    }
                    var errorMessage = (response && response.message) ? response.message : null;
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(errorMessage);
                    }
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createErrorObject({}), response, params);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    if (!suppressErrors) {
                        PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                    }
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "getProfilePhotos", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    if (hasOnComplete) {
                        onComplete(PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                    }
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "getProfilePhotos" });
            if (hasOnComplete) {
                onComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
            }
        }
    };

    var getSilhouetteUrl = function (gender, useSquareUrl) {
        var intGender = (gender === 1 || gender === "1" || gender === "Male" || gender === "male") ? 1 : 2;
        var suffix = (useSquareUrl) ? "q" : "";
        return "https://pmi.peoplemedia.com/pmicontent/common/stock/noPhoto-" + intGender.toString() + suffix + ".png";
    };

    var getPhotoInfo = function (apiPhotoData) {
        var photoInfo = {
            photoId: null,
            photoUrl: "",
            silhouette: false,
            photoCount: 0,
            errorUrl: ""
        };
        if (apiPhotoData.Photo &&
            typeof (apiPhotoData.Photo) === "object" &&
            apiPhotoData.Photo.SquareUrl &&
            typeof (apiPhotoData.Photo.SquareUrl) === "string") {
            photoInfo.photoUrl = apiPhotoData.Photo.SquareUrl;
            photoInfo.photoId = apiPhotoData.Photo.PhotoId;
        } else {
            // use silhouette photo
            photoInfo.silhouette = true;
            photoInfo.photoUrl = getSilhouetteUrl(apiPhotoData.Gender, true);
        }
        photoInfo.errorUrl = getSilhouetteUrl(apiPhotoData.Gender, true);

        // populate photo count (note: primary photo can be a silhouette (unapproved) and still have photos > 0)
        if (apiPhotoData.PhotoCount && typeof (apiPhotoData.PhotoCount) === "number" && apiPhotoData.PhotoCount > 0) {
            photoInfo.photoCount = apiPhotoData.PhotoCount;
        }
        return photoInfo;
    };

    return {
        getProfilePhotos: getProfilePhotos,
        getSilhouetteUrl: getSilhouetteUrl,
        getPhotoInfo: getPhotoInfo
    };
})(jQuery);

// jQuery plugin used by PeopleMedia.ImpressionTracking
(function ($jq) {
    "use strict";
    $jq.fn.onImpression = function (options) {

        var settings = $jq.extend({
                offset: 0,
                callback: null,
                attribute: "",
                alwayscallback: false,
                scrollable: ""
            },
            options);

        var $window = $jq(window),
            $scrollable = $jq(settings.scrollable),
            onImpressionElements = this,
            loaded;

        this.one("onImpression", function () {
            if (typeof settings.callback === "function") settings.callback.call(this, this.getAttribute(settings.attribute));
        });

        this.on("alwaysOnImpression", function () {
            if (typeof settings.callback === "function") settings.callback.call(this, this.getAttribute(settings.attribute));
        });

        function onImpression() {
            var inview = onImpressionElements.filter(function () {
                var $e = $jq(this);
                if ($e.is(":hidden")) return;
                var wt = $window.scrollTop(),
                    wb = wt + $window.height(),
                    et = $e.offset().top,
                    eb = et + $e.height();
                var inScrollable = false;
                if ($scrollable.length) {
                    var scrollTop = $scrollable.scrollTop(),
                        scrollBottom = scrollTop + $scrollable.height();
                    inScrollable = (eb >= scrollTop - settings.offset && et <= scrollBottom + settings.offset);
                }
                return (eb >= wt - settings.offset && et <= wb + settings.offset) || inScrollable;
            });

            if (settings.alwayscallback) {
                loaded = inview.trigger("alwaysOnImpression");
            }
            else {
                loaded = inview.trigger("onImpression");
                onImpressionElements = onImpressionElements.not(loaded);
            }
        }

        // Only run  code if the callback is available, else there is no point
        if (typeof settings.callback === "function") {
            $window.on("scroll.onImpression resize.onImpression lookup.onImpression", onImpression);
            if ($scrollable.length) {
                $scrollable.on("scroll.onImpression resize.onImpression lookup.onImpression", onImpression);
            }
            onImpression();
        }

        return this;
    };

})(window.jQuery);

// SetCaptureImpression is looking for items that have data attributes "data-encodedmemberid" "data-pagenumber" "data-ordinalposition"
PeopleMedia.ImpressionTracking = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.ImpressionTracking";

    var _timeoutTime = 850;
    var _captureImpressionTimeout = null;
    var _captureImpressionArray = [];
    var _searchType = 0;

    var RunCaptureImpression = function () {
        if (_captureImpressionArray && _captureImpressionArray.length > 0) {
            var startTime = new Date().getTime();
            var timeoutValue = 20000;
            var url = "/v3/Ajax/CaptureProfileImpression";
            var ajaxData = {
                'impressionData': _captureImpressionArray,
                'searchType': _searchType
            };
            // save the impressions!
            $jq.ajax({
                contentType: 'application/json; charset=utf-8',
                url: url,
                type: "post",
                dataType: "json",
                data: JSON.stringify(ajaxData),
                success: function (response) {
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    // PeopleMedia.Popups.ShowGenericMessage(PeopleMedia.Popups.AjaxErrorMessage());
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "RunCaptureImpression", duration: duration }, jqXHR, textStatus, errorThrown);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
            _captureImpressionArray = [];
        }
    };

    var SetCaptureImpression = function (impressionClass, searchType) {
        _searchType = searchType;

        $jq(impressionClass).onImpression({
            offset: -5,
            callback: function () {
                var $this = $jq(this);
                var encodedMemberId = $this.attr("data-encodedmemberid");
                var pageNumber = $this.attr("data-pagenumber");
                var ordinalPosition = $this.attr("data-ordinalposition");
                if (encodedMemberId) {

                    // Reset CaptureImpressionTimeout and add the new value before submitting again
                    clearTimeout(_captureImpressionTimeout);

                    var details = {
                        "EncodedMemberId": encodedMemberId,
                        "PageNumber": pageNumber,
                        "OrdinalPosition": ordinalPosition
                    };

                    // add new details to the overall array
                    _captureImpressionArray.push(details);

                    // apply a timeout so we can gather extra Impressions if possible
                    _captureImpressionTimeout = setTimeout(function () {
                        RunCaptureImpression();
                    }, _timeoutTime);

                }
            }
        });
    };

    return {
        RunCaptureImpression: RunCaptureImpression,
        SetCaptureImpression: SetCaptureImpression
    };
})(jQuery);

PeopleMedia.Pagination = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Pagination";

    var calculatePageCount = function (totalRecords, perPage) {
        var pageCount = 0;
        if (!isNaN(totalRecords) && !isNaN(perPage) && totalRecords > 0 && perPage > 0) {
            pageCount = Math.ceil(totalRecords / perPage);
            if (pageCount < 1) {
                pageCount = 1;
            }
        }
        return pageCount;
    };

    var getPaginationContainerInnerHtml = function (currentPage) {
        var prevPage = currentPage - 1;
        var nextPage = currentPage + 1;
        return "<a class=\"page inactive prev\" href=\"javascript:;\" data-pagenumber=\"" + prevPage + "\"><i class=\"back\"></i> Prev</a>" + 
            "<div class=\"page-numbers\"></div>" +
            "<a class=\"page inactive next\" href=\"javascript:;\" data-pagenumber=\"" + nextPage + "\">Next <i class=\"forward\"></i></a>";
    };

    var getPageNumberItems = function (params) {
        var currentPage = params.currentPage;
        var totalPages = params.totalPages;
        var pageNum;
        var result = [];

        // Logic assumes 7 PageNumberItem slots to fill (where a slot is either a page link or an ellipse)
        // since that is the minimum for the format:       1 ... 4 5 6 ... 9
        // We want to always show links for first, last, previous and next pages.
        if (totalPages <= 7) {
            // no ellipsis format: 1 2 3 4 5 6 7
            for (pageNum = 1; pageNum <= totalPages; pageNum++) {
                result.push({
                    pageNumber: pageNum,
                    isCurrentPage: (pageNum === currentPage),
                    isEllipsis: false
                });
            }
        } else {
            // There are more than 7 items with at least one ellipsis
            if (currentPage <= 4) {
                // Ellipsis at end format: 1 2 3 4 5 ... 9
                // Fill first five slots sequentially from 1 to 5
                for (pageNum = 1; pageNum <= 5; pageNum++) {
                    result.push({
                        pageNumber: pageNum,
                        isCurrentPage: (pageNum === currentPage),
                        isEllipsis: false
                    });
                }

                result.push({
                    isEllipsis: true
                });

                // Show single link to last page
                result.push({
                    pageNumber: totalPages,
                    isCurrentPage: (currentPage === totalPages),
                    isEllipsis: false
                });
            } else if (totalPages - currentPage <= 3) {
                // Ellipsis at start format: 1 ... 5 6 7 8 9
                // Show single link to first page
                result.push({
                    pageNumber: 1,
                    isCurrentPage: (currentPage === 1),
                    isEllipsis: false
                });

                result.push({
                    isEllipsis: true
                });

                // Fill last 5 slots sequentially starting with last page - 4
                for (pageNum = totalPages - 4; pageNum <= totalPages; pageNum++) {
                    result.push({
                        pageNumber: pageNum,
                        isCurrentPage: (pageNum === currentPage),
                        isEllipsis: false
                    });
                }
            } else {
                // Two ellipsis format: 1 ... 4 5 6 ... 9
                // This format won't be shown if the total pages is 8
                // Show single link to first page
                result.push({
                    pageNumber: 1,
                    isCurrentPage: (currentPage === 1),
                    isEllipsis: false
                });

                // first ellipsis
                result.push({
                    isEllipsis: true
                });

                // add three middle links for previous, current and next page
                for (pageNum = currentPage - 1; pageNum <= currentPage + 1; pageNum++) {
                    result.push({
                        pageNumber: pageNum,
                        isCurrentPage: (pageNum === currentPage),
                        isEllipsis: false
                    });
                }

                // second ellipsis
                result.push({
                    isEllipsis: true
                });

                // Show single link to last page
                result.push({
                    pageNumber: totalPages,
                    isCurrentPage: (currentPage === totalPages),
                    isEllipsis: false
                });
            }
        }
        return result;

    };

    var convertPageNumberItemsToHtml = function (pageNumberItems) {
        var result = "";
        if (pageNumberItems && pageNumberItems.length > 0) {
            for (var i = 0; i < pageNumberItems.length; i++) {
                if (pageNumberItems[i].isEllipsis) {
                    result += "<span>...</span>";
                } else if (pageNumberItems[i].isCurrentPage) {
                    result += "<a href=\"javascript:;\" class=\"active\" data-pagenumber=\"" + pageNumberItems[i].pageNumber + "\">" + pageNumberItems[i].pageNumber + "</a>";
                } else {
                    result += "<a href=\"javascript:;\" class=\"clickable\" data-pagenumber=\"" + pageNumberItems[i].pageNumber + "\">" + pageNumberItems[i].pageNumber + "</a>";
                }
            }
        }
        return result;
    };

    var getPaginationNamespace = function (namespace) {
        var result = namespace || ".pmpagination";
        if (namespace && namespace.substring(0, 1) !== ".") {
            result = "." + namespace;
        }
        return result;
    };

    var updatePrevNextButton = function ($button, pageNumber, isActive) {
        if (isActive) {
            $button.removeClass("inactive");
        } else {
            $button.addClass("inactive");
        }
        $button.attr("data-pagenumber", pageNumber);
    };

    var updatePageNumbers = function ($pageNumbers, currentPage, totalPages) {
        var pageNumberItems = getPageNumberItems({
            currentPage: currentPage,
            totalPages: totalPages
        });
        var pageNumberHtml = convertPageNumberItemsToHtml(pageNumberItems);
        $pageNumbers.empty().html(pageNumberHtml);
    };

    var convertPageNumber = function (pageString) {
        var pageNumber = parseInt(pageString, 10);
        if (!isNaN(pageNumber)) {
            return pageNumber;
        }
        return null;
    };

    var updatePaginationContainer = function (params) {
        var $container = params.$container || $jq("<div></div>");
        if (!$container.hasClass("pagination-container")) {
            $container.addClass("pagination-container");
        }

        var namespace = getPaginationNamespace(params.namespace);
        var totalRecords = params.totalRecords;
        var perPage = params.perPage;
        var currentPage = params.currentPage;

        var totalPages = calculatePageCount(totalRecords, perPage);
        if (currentPage < 1) {
            currentPage = 1;
        }

        if (currentPage > totalPages && totalPages > 0) {
            // set to last page if we go over the total number of pages
            currentPage = totalPages;
        }

        var hasPreviousPage = (currentPage > 1);
        var hasNextPage = (currentPage < totalPages);

        $container.empty();
        if (totalPages === 0) {
            $container.addClass("pagination-empty").html("<div>&nbsp;</div>");
        } else {
            var paginationHtml = getPaginationContainerInnerHtml(currentPage);
            $container.removeClass("pagination-empty").html(paginationHtml);
        }

        var $pageNumbers = $container.find(".page-numbers");
        var $prev = $container.find(".page.prev");
        var $next = $container.find(".page.next");

        updatePageNumbers($pageNumbers, currentPage, totalPages);
        updatePrevNextButton($prev, (currentPage - 1), hasPreviousPage);
        updatePrevNextButton($next, (currentPage + 1), hasNextPage);

        $prev.off("click").on("click", function (event) {
            var $this = $jq(this);
            var isActive = !($this.hasClass("inactive"));
            var pageNumber = convertPageNumber($this.attr("data-pagenumber"));
            $container.trigger("clickprev" + namespace, { originalEvent: event, isActive: isActive, pageNumber: pageNumber });
        });
        $next.off("click").on("click", function (event) {
            var $this = $jq(this);
            var isActive = !($this.hasClass("inactive"));
            var pageNumber = convertPageNumber($this.attr("data-pagenumber"));
            $container.trigger("clicknext" + namespace, { originalEvent: event, isActive: isActive, pageNumber: pageNumber });
        });
        $pageNumbers.off("click").on("click", "a[data-pagenumber]", function (event) {
            var $this = $jq(this);
            var isCurrentPage = $this.hasClass("active");
            var pageNumber = convertPageNumber($this.attr("data-pagenumber"));
            $container.trigger("clickpage" + namespace, { originalEvent: event, pageNumber: pageNumber, isCurrentPage: isCurrentPage });
        });

        return $container;
    };

    return {
        updatePaginationContainer: updatePaginationContainer
    };
})(jQuery);

PeopleMedia.Badges = (function($jq) {
    "use strict";
    var Oid = "PeopleMedia.Badges";

    var fixBadgeBorders = function ($badges) {
        $badges.each(function () {
            var $this = $jq(this);
            var badgeAttr = ($this.attr("data-badge") || "").trim();
            if (badgeAttr.length > 0) {
                $this.addClass("badge-border");
            } else {
                $this.removeClass("badge-border").removeAttr("data-badge");
            }
        });
    };

    var getUserCounts = function (params) {
        try {
            var startTime = new Date().getTime();
            var url = "/v3/Ajax/GetUserCounts";
            var timeoutValue = 5000;
            var pageDefinitionId = typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null;
            var ajaxData = {
                "PageId": pageDefinitionId
            };
            $jq.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    getUserCountsComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "getUserCounts", duration: duration, params: params },
                        jqXHR,
                        textStatus,
                        errorThrown);
                    getUserCountsComplete(
                        PeopleMedia.createAjaxErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }),
                        null,
                        params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "getUserCounts" });
            getUserCountsComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
        }
    };

    var getUserCountsComplete = function (err, response, params) {
        if (err || !response || response.status !== "Success" || !response.data) {
            // there was an error
            // console.log("getUserCountsComplete: error", err);
            if (!err) {
                // 204 No Content
                err = PeopleMedia.createErrorObject({ reason: "Missing data" });
            }
            if (params && typeof (params.onComplete) === "function") {
                params.onComplete(err, response, params);
            }
            return;
        }

        if (response.redirect) {
            location.href = response.redirect;
        }

        $jq(window).trigger("badgecountchange", response.data);

        // console.log("getUserCountsComplete: success", response.data);
        if (params && typeof (params.onComplete) === "function") {
            params.onComplete(err, response, params);
        }
    };

    return {
        getUserCounts: getUserCounts,
        fixBadgeBorders: fixBadgeBorders
    };
})(jQuery);

// has dependencies on jQuery and moment.js
PeopleMedia.BoostTimer = (function ($jq, moment) {
    "use strict";
    var timer = null;
    var secondsRemaining = 0;

    var getFormattedTime = function (seconds) {
        return moment.utc(seconds * 1000).format('HH:mm:ss');
    };

    var getTimerData = function () {
        return {
            secondsRemaining: secondsRemaining,
            formattedTime: getFormattedTime(secondsRemaining)
        };
    };

    var stopCountdown = function () {
        if (timer !== null) {
            clearInterval(timer);
        }
        timer = null;
        var timerData = getTimerData();
        $jq(window).trigger("boosttimerend", timerData);
    };

    var timerUpdate = function () {
        secondsRemaining--;

        if (secondsRemaining <= 0) {
            stopCountdown();
        } else {
            var timerData = getTimerData();
            $jq(window).trigger("boosttimerchange", timerData);
        }
    };

    var startCountdown = function (options) {
        if (typeof (secondsRemaining) === "undefined" || secondsRemaining === null || secondsRemaining < 0) {
            secondsRemaining = 0;
        }
        var alreadyRunning = (secondsRemaining > 0);
        if (options) {
            if (!alreadyRunning && typeof (options.secondsRemaining) === "number" && options.secondsRemaining > 0) {
                secondsRemaining = options.secondsRemaining;
            }
        }
        if (!alreadyRunning && secondsRemaining > 0) {
            var timerData = getTimerData();
            $jq(window).trigger("boosttimerchange", timerData);
            if (timer !== null) {
                clearInterval(timer);
            }
            timer = setInterval(timerUpdate, 1000);
        }
    };

    return {
        startCountdown: startCountdown,
        stopCountdown: stopCountdown
    };
})(jQuery, moment);

PeopleMedia.AccessibilityHelper = (function ($jq) {
    "use strict";

    var checkForEnterOrSpaceKeypress = function (e) {
        // proceed with callback if enter key or space key was pressed
        // (Note: enter key = 13, space key = 32)
        if (e && typeof (e) === "object" && (e.which === 13 || e.which === 32)) {
            // prevent scrolling the page down if space bar was pressed
            if (e.which === 32) {
                e.preventDefault();
            }
            if (e.data.callback && typeof (e.data.callback) === "function") {
                e.data.callback(e);
            }
        }
    };

    var removeOutlineOnClick = function ($parent) {
        $parent.on("mousedown", function () {
            $parent.addClass("no-outline");
        });
        $parent.on('keydown', function () {
            $parent.removeClass("no-outline");
        });
    };

    return {
        checkForEnterOrSpaceKeypress: checkForEnterOrSpaceKeypress,
        removeOutlineOnClick: removeOutlineOnClick
    };
})(jQuery);

// has dependencies on jQuery and moment.js
PeopleMedia.EventsTimer = (function ($jq, moment) {
    "use strict";
    var timer = null;
    var secondsRemaining = 0;

    var getFormattedTime = function (seconds) {
        // show days if time left is more than 24hrs
        if (typeof(seconds) === "number" && seconds >= 86400) {
            var daysLeft = Math.floor(seconds / 86400);
            return daysLeft + " day" + (daysLeft > 1 ? "s" : "");
        }
        return moment.utc(seconds * 1000).format('HH:mm:ss');
    };

    var getTimerData = function () {
        return {
            secondsRemaining: secondsRemaining,
            formattedTime: getFormattedTime(secondsRemaining)
        };
    };

    var stopCountdown = function () {
        if (timer !== null) {
            clearInterval(timer);
        }
        timer = null;
        var timerData = getTimerData();
        $jq(window).trigger("eventstimerend", timerData);
    };

    var timerUpdate = function () {
        secondsRemaining--;

        if (secondsRemaining <= 10800) {
            stopCountdown();
        } else {
            var timerData = getTimerData();
            $jq(window).trigger("eventstimerchange", timerData);
        }
    };

    var startCountdown = function (options) {
        if (typeof (secondsRemaining) === "undefined" || secondsRemaining === null || secondsRemaining < 0) {
            secondsRemaining = 0;
        }
        var alreadyRunning = (secondsRemaining > 0);
        if (options) {
            if (!alreadyRunning && typeof (options.secondsRemaining) === "number" && options.secondsRemaining > 0) {
                secondsRemaining = options.secondsRemaining;
            }
        }
        if (!alreadyRunning && secondsRemaining > 0) {
            var timerData = getTimerData();
            $jq(window).trigger("eventstimerchange", timerData);
            if (timer !== null) {
                clearInterval(timer);
            }
            timer = setInterval(timerUpdate, 1000);
        }
    };

    return {
        startCountdown: startCountdown,
        stopCountdown: stopCountdown
    };
})(jQuery, moment);

PeopleMedia.ArkoseCaptcha = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.ArkoseCaptcha";
    var _arkoseTimeout = 15000;
    var _captchaToken = null;
    var _captchaLocation = null;
    var _pageDefinitionId = null;
    var _onValidateComplete = null;
    var _$form = $jq();
    var _selector = null;

    var _isScriptLoaded = false;
    var _scriptsReady = false;
    var _scriptsFailed = false;
    var _enforceTimer = null;
    var _arkose = null;

    var updateCaptchaTokenInForm = function () {
        if (_$form && _$form.length > 0 && _captchaToken) {
            var $inputToken = _$form.find("input[name='CaptchaToken']");
            if ($inputToken.length > 0) {
                $inputToken.val(_captchaToken);
            }
        }
    };

    var includeCaptchaFieldsInForm = function (params) {
        var captchaToken = params && params.captchaToken ? params.captchaToken : "";
        var captchaLocation = params && params.captchaLocation ? params.captchaLocation : "";
        var pageDefinitionId = params && params.pageDefinitionId ? params.pageDefinitionId : (typeof (PeopleMedia.PageDefinitionId) !== "undefined" ? PeopleMedia.PageDefinitionId : null);
        var $form = params && params.$form ? params.$form : $jq();
        if ($form && $form.length > 0) {
            var $inputToken = $form.find("input[name='CaptchaToken']");
            var $inputAction = $form.find("input[name='CaptchaLocation']");
            var $inputPageDefinitionId = $form.find("input[name='PageDefinitionId']");

            if ($inputToken.length === 0) {
                $form.append("<input name='CaptchaToken' type='hidden' value='" + captchaToken + "'>");
            } else {
                $inputToken.val(captchaToken);
            }

            if ($inputAction.length === 0) {
                $form.append("<input name='CaptchaLocation' type='hidden' value='" + captchaLocation + "'>");
            } else {
                $inputAction.val(captchaLocation);
            }

            if ($inputPageDefinitionId.length === 0) {
                $form.append("<input name='PageDefinitionId' type='hidden' value='" + pageDefinitionId + "'>");
            } else {
                $inputPageDefinitionId.val(pageDefinitionId);
            }
        }
    };

    var onArkoseEnforcementComplete = function (response) {
        var token = (response && response.token) ? response.token.toString() : "";
        _captchaToken = token;
        updateCaptchaTokenInForm();
        if (typeof (_onValidateComplete) === "function") {
            _onValidateComplete(null, response);
        }
    };

    var timerExpired = function() {
        clearTimeout(_enforceTimer);
        if (!_isScriptLoaded) {
            _scriptsReady = false;
            _isScriptLoaded = false;
            _scriptsFailed = true;
        }
    };

    var setArkose = function(enforcement) {
        _arkose = enforcement;
    };

    var setupEnforcement = function(enforcement) {
        enforcement.setConfig({
            onReady: function() {
                _scriptsReady = true;
            },
        });
        setArkose(enforcement);
        _isScriptLoaded = true;
    };

    var loadScripts = function () {
        try {
            _scriptsReady = false;
            var arkoseScript = document.createElement("script");
            arkoseScript.id = "arkosescript";
            arkoseScript.type = "text/javascript";
            var attrib = document.createAttribute("data-callback");
            attrib.value = "setupEnforcement";
            arkoseScript.setAttributeNode(attrib);
            arkoseScript.src = "https://client-api.arkoselabs.com/v2/" + PeopleMediaConfig.Configuration.arkoseSiteKey + "/api.js";
            document.head.appendChild(arkoseScript);
        }
        catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "loadArkose" });
            _scriptsReady = false;
            _isScriptLoaded = false;
            _scriptsFailed = true;
        }
    };

    var execute = function () {
        if (!!_arkose && _scriptsReady) {
            _arkose.setConfig({
                selector: _selector,
                onCompleted: onArkoseEnforcementComplete,
                onReady: function () {
                    _scriptsReady = true;
                },
            });
            _arkose.run();
        }
    };

    var initialize = function (options) {
        _captchaToken = null;
        _captchaLocation = null;
        _pageDefinitionId = null;
        _onValidateComplete = null;
        _$form = $jq();
        _selector = null;
        if (options) {
            if (options.captchaLocation) {
                _captchaLocation = options.captchaLocation;
            }
            if (options.pageDefinitionId) {
                _pageDefinitionId = options.pageDefinitionId;
            }
            if (options.$form && options.$form.length > 0) {
                _$form = options.$form;
            }
            if (options.selector) {
                _selector = options.selector;
            }
            if (typeof (options.onValidateComplete) === "function") {
                _onValidateComplete = options.onValidateComplete;
            }
            if (options.includeCaptchaFieldsInForm === true && _$form && _$form.length > 0) {
                includeCaptchaFieldsInForm({
                    captchaToken: _captchaToken,
                    captchaLocation: _captchaLocation,
                    $form: _$form,
                    pageDefinitionId: _pageDefinitionId
                });
            }
        }
        window.setupEnforcement = setupEnforcement;
        loadScripts();
        _enforceTimer = window.setTimeout(timerExpired, _arkoseTimeout);
    };

    return {
        get scriptsFailed() { return _scriptsFailed; },
        get scriptsReady() { return _scriptsReady; },
        get selector() { return _selector; },
        initialize: initialize,
        includeCaptchaFieldsInForm: includeCaptchaFieldsInForm,
        updateCaptchaTokenInForm: updateCaptchaTokenInForm,
        onArkoseEnforcementComplete: onArkoseEnforcementComplete,
        execute: execute
    };
})(jQuery);

// has dependencies on jQuery and lottie
PeopleMedia.Animations = (function ($jq) {
    var Oid = "PeopleMedia.Animations";
    var animations = {};
    var _loaderTimeout = null;
    var _loaderDelay = 3000;

    var setupLottieAnimations = function () {
        try {
            // preload the animation files
            $jq.ajax({
                'url': PeopleMediaConfig.Configuration.themeAnimationPath + "/Loader.json",
                'dataType': "json",
                'success': function (data) {
                    document.querySelectorAll('.loader2 .spinner .lottie-container').forEach(
                        function (item) {
                            var animation = lottie.loadAnimation({
                                container: item,
                                animationData: data,
                                renderer: 'svg',
                                loop: true,
                                autoplay: false,
                                name: "Loader"
                            });
                            animations["loader"] = animation;
                            $jq(".loader2").addClass("lottie");
                            $jq(".loader2 .spinner").removeClass("default-image");
                        });
                }
            });
            $jq(window).trigger("lottieload");
        }
        catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "setupLottieAnimations" });
        }
    };

    var loadLottie = function () {
        try {
            if (typeof (lottie) === "undefined") {
                var lottieScript = document.createElement("script");
                lottieScript.id = "lottiescript";
                lottieScript.type = "text/javascript";
                lottieScript.onload = setupLottieAnimations;
                lottieScript.src = PeopleMediaConfig.Configuration.baseImagePath + "/scripts/lottie_5.7.12.min.js";
                document.head.appendChild(lottieScript);
            } else {
                setupLottieAnimations();
            }
        }
        catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "loadLottie" });
        }
    };

    var showLoader = function (options) {
        var $loader = $jq(".loader2");
        var useLegacy = options && options.legacy === true;
        if (useLegacy) {
            // use animated gif instead of lottie
            $loader.find(".lottie-container").addClass("hidden");
            $loader.removeClass("hidden").find(".spinner").addClass("default-image");
            return;
        }
        var anim = animations["loader"];
        $loader.removeClass("hidden");
        if (typeof (lottie) === "object" && typeof (anim) === "object") {
            anim.play();
        }
    };

    var hideLoader = function (options) {
        var $loader = $jq(".loader2");
        var useLegacy = options && options.legacy === true;
        if (useLegacy) {
            $loader.addClass("hidden");
            if ($loader.hasClass("lottie")) {
                // reset back to default behavior
                $loader.find(".lottie-container").removeClass("hidden");
                $loader.find(".spinner").removeClass("default-image");
            }
            return;
        }
        var anim = animations["loader"];
        if (typeof (lottie) === "object" && typeof (anim) === "object") {
            anim.stop();
        }
        $loader.addClass("hidden");
    };

    var loaderVisible = function () {
        var $loader = $jq(".loader2");
        return $loader.length > 0 && !($loader.hasClass("hidden"));
    };

    var cancelDelayedLoader = function (options) {
        if (_loaderTimeout) {
            // console.log("Cancelling delayed spinner " + spinnerTimeout + " at " + moment().format("YYYY-MM-DD HH:mm:ss.SSS"));
            clearTimeout(_loaderTimeout);
        }
        _loaderTimeout = null;
        if (PeopleMedia.Animations.loaderVisible()) {
            hideLoader(options);
        }
    };

    var showDelayedLoader = function (options) {
        var delayMilliseconds = (options && typeof (options.delayMilliseconds) === "number" && options.delayMilliseconds >= 0) ? options.delayMilliseconds : _loaderDelay;
        if (_loaderTimeout) {
            // console.log("Duplicate delayed spinner " + _loaderTimeout + " detected at " + new Date());
            clearTimeout(_loaderTimeout);
        }
        // wait before showing spinner
        _loaderTimeout = setTimeout(function () {
            if (_loaderTimeout) {
                // console.log("Showing delayed spinner " + _loaderTimeout + " at " + new Date());
                clearTimeout(_loaderTimeout);
                _loaderTimeout = null;
                showLoader(options);
            }
        }, delayMilliseconds);
        // console.log("Starting delayed spinner " + _loaderTimeout + " at " + new Date());
    };

    var showLoaderBeforeRedirect = function (options) {
        // ios doesn't like doing this...
        if (!PeopleMedia.detectIOS()) {
            PeopleMedia.Animations.showLoader(options);
        }
    };

    var init = function () {
        loadLottie();
    };

    return {
        init: init,
        showLoader: showLoader,
        hideLoader: hideLoader,
        loaderVisible: loaderVisible,
        showDelayedLoader: showDelayedLoader,
        cancelDelayedLoader: cancelDelayedLoader,
        showLoaderBeforeRedirect: showLoaderBeforeRedirect
    };
})(jQuery);

PeopleMedia.Geo = (function () {
    var Oid = "PeopleMedia.Geo";

    var getPostalCodeLocation = function (params) {
        try {
            var postalCode = (params.postalCode || "").trim();

            var startTime = new Date().getTime();
            var url = "/v3/ajax/GetLocationByPostalCode";
            var timeoutValue = 3000;
            var ajaxData = {
                "PostalCode": postalCode
            };
            jQuery.ajax({
                headers: PeopleMedia.sitePostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function (response) {
                    getPostalCodeLocationComplete(null, response, params);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    PeopleMedia.Logger.LogAjaxErrorXHR("Ajax request failure", url, { Oid: Oid, method: "trackCustomMemberEvents", duration: duration, params: params }, jqXHR, textStatus, errorThrown);
                    getPostalCodeLocationComplete(PeopleMedia.createErrorObject({ jqXHR: jqXHR, errorThrown: errorThrown }), null, params);
                },
                async: true,
                cache: false,
                timeout: timeoutValue,
                suppressErrors: true
            });

        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "getPostalCodeLocation" });
            getPostalCodeLocationComplete(PeopleMedia.createErrorObject({ ex: ex }), null, params);
        }
    };

    var getPostalCodeLocationComplete = function (err, response, params) {
        if (err || !response || response.status !== "Success" || !response.Location) {
            console.log("error validating postal code");
            if (params && typeof (params.onComplete) === "function") {
                params.onComplete({
                    Location: null
                });
            }
            return;
        }
        if (params && typeof (params.onComplete) === "function") {
            params.onComplete({
                Location: response.Location
            });
        }
    };

    var validatePostalCodeFormatIsUsa = function (input) {
        var checkPostalCode = /^[0-9]{5}$/;
        return checkPostalCode.test(input);
    };

    var validatePostalCodeFormatIsCanada = function (input) {
        var checkPostalCodeCanada = /^[ABCEGHJKLMNPRSTVXY][0-9][A-Z][\s]*[0-9][A-Z][0-9]$/i;
        return checkPostalCodeCanada.test(input);
    };

    return {
        validatePostalCodeFormatIsUsa: validatePostalCodeFormatIsUsa,
        validatePostalCodeFormatIsCanada: validatePostalCodeFormatIsCanada,
        getPostalCodeLocation: getPostalCodeLocation
    };
})();

PeopleMedia.Constants = {
    Gender: {
        get Male() { return 1; },
        get Female() { return 2; }
    },
    ConnectionType: {
        get InboundNonMutual() { return 1; },
        get OutboundNonMutual() { return 2; },
        get Mutual() { return 3; }
    },
    ThreadType: {
        get Message() { return 1; },
        get Like() { return 2; }
    }
};

// jQuery 1.9 has removed the '$.browser' property, but jquery.ba-bbq relies on it, so patch it here if it's missing.
// This has been copied from jQuery migrate 1.1.1.
if (!jQuery.browser) {
    var uaMatch = function (ua) {
        ua = ua.toLowerCase();

        var match = /(chrome)[ \/]([\w.]+)/.exec(ua) ||
            /(webkit)[ \/]([\w.]+)/.exec(ua) ||
            /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) ||
            /(msie) ([\w.]+)/.exec(ua) ||
            ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
            [];

        return {
            browser: match[1] || "",
            version: match[2] || "0"
        };
    };

    var matched = uaMatch(navigator.userAgent);
    var browser = {};

    if (matched.browser) {
        browser[matched.browser] = true;
        browser.version = matched.version;
    }

    // Chrome is Webkit, but Webkit is also Safari.
    if (browser.chrome) {
        browser.webkit = true;
    } else if (browser.webkit) {
        browser.safari = true;
    }

    jQuery.browser = browser;
}

$jq(PeopleMedia.Timer.Init);
$jq(PeopleMedia.Popups.Init);
$jq(PeopleMedia.Animations.init);

